import React, { isValidElement, PureComponent } from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

import Backdrop from './Backdrop';
import Menu from './Menu';
import Opener from './Opener';

import { Context } from './Provider';

const Container = styled.div`
  display: inline-block;
  position: relative;
`;

export default class PopupMenu extends PureComponent {
  state = { open: false };

  menuId = shortid.generate();

  componentDidUpdate() {
    const { openMenuId, setOpenMenuId } = this.context;

    // If we're not within a `Context`, don't worry about updates.
    if (typeof setOpenMenuId !== 'function') return;

    const { open } = this.state;

    if (open === true && openMenuId !== this.menuId) {
      // Overwrite ESLint here. Since we're checking for `open` we're avoiding an infinite loop.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ open: false });
    }
  }

  handleClick = () => {
    const { setOpenMenuId } = this.context;
    const { open } = this.state;

    // If the menu is open, tell the context that a menu isn't open. If the menu isn't open
    // tell the context which menu has been opened.
    if (setOpenMenuId) setOpenMenuId(open ? null : this.menuId);

    this.setState({ open: !open });
  };

  static contextType = Context;

  render() {
    const {
      ariaLabel,
      children,
      collapse = false,
      dataTestId = 'molecule-popup-menu',
      direction = 'down',
      name: openerName,
      opener = 'more',
      reverseContrast = false,
    } = this.props;

    const { open } = this.state;

    const isOpen =
      ((Array.isArray(children) && children.length > 0) || (isValidElement(children) && isValidElement !== null)) &&
      open;

    return (
      <>
        {isOpen && <Backdrop onClick={this.handleClick} />}
        <Container data-testid={dataTestId} opener={opener}>
          <Opener
            aria-label={ariaLabel}
            expanded={isOpen}
            menuId={this.menuId}
            name={openerName}
            onClick={this.handleClick}
            opener={opener}
            reverseContrast={reverseContrast}
          />
          <Menu
            aria-hidden={String(!isOpen)}
            collapse={collapse}
            direction={direction}
            id={this.menuId}
            onClick={this.handleClick}
          >
            {children}
          </Menu>
        </Container>
      </>
    );
  }
}

export { default as Group } from './Group';
export { default as Option } from './Option';
export { default as PopupMenuProvider } from './Provider';
