import React from 'react';

import Container from './Container';
import Text from './Text';

const Badge = ({ children, dataTestId = 'atom-badge', ...props }) => {
  return (
    <Container data-testid={dataTestId} {...props}>
      <Text>{children}</Text>
    </Container>
  );
};

export default Badge;
