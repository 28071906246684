/* shared module for Search and Trace apps */
import { endOfDay, startOfDay, subDays } from 'date-fns';

import { Customer, DispositionWithLabel } from './types';

export const DEFAULT_DAYS_BACK = 30;
export const DEFAULT_INITIAL_DATE_INTERVAL = 30;
export const DEFAULT_RESULTS_LIMIT = 100;
export const DEFAULT_WAIT_SECS = 60;

export const ALL_CUSTOMERS: Customer = {
  isDeleted: false,
  value: {
    client_name: null,
    client_id: null,
  },
  label: 'ALL CUSTOMERS',
};

export const DISPOSITIONS: DispositionWithLabel[] = [
  { value: '', label: 'Any' },
  { value: 'MALICIOUS', label: 'MALICIOUS' },
  { value: 'MALICIOUS-BEC', label: 'MALICIOUS-BEC' },
  { value: 'SUSPICIOUS', label: 'SUSPICIOUS' },
  { value: 'SPOOF', label: 'SPOOF' },
  { value: 'SPAM', label: 'SPAM' },
  { value: 'BULK', label: 'BULK' },
];

export const initDateRange = [startOfDay(subDays(new Date(), DEFAULT_INITIAL_DATE_INTERVAL)), endOfDay(new Date())];

export const sortAnnotate = (customers: Customer[], useAll = false): Customer[] => {
  // label deleted customers as inactive for user awareness
  const deleted = customers
    .filter((customer) => customer.isDeleted)
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  deleted.forEach((c) => (c.label = c.label + ' [inactive]'));

  const active = customers
    .filter((customer) => !customer.isDeleted)
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  const allCustomers = [...active, ...deleted];

  // if supported, view all customers (searchAllCustomers=true)
  if (useAll) {
    const area1 = customers.find((c) => c.label === 'Area 1');
    if (area1) {
      ALL_CUSTOMERS.value.client_id = area1.value.client_id;
      ALL_CUSTOMERS.value.client_name = area1.value.client_name;
      allCustomers.unshift(ALL_CUSTOMERS);
    }
  }
  return allCustomers;
};

export const compare = (a: number, b: number): number => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const formatUTC = (date: string): string => date.replace('T', ' ');

export const pluralizeMessage = (count: number, plural: string, singular: string): string =>
  `${count > 1 ? plural : singular}`;
