import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import ConditionalRender from '../ConditionalRender';
import Link from '../atoms/Link';
import PaddedContent from '../atoms/PaddedContent';
import Title from '../Title';
import Info from '../molecules/Info';
import Stack from '../templates/Stack';

import Container from './Container';
import Description from './Description';

// TODO: This needed to be more flexible than just accepting a button
// title: Can be a string or another React element (for example a link)

const BlockHeader = ({
  action,
  children,
  dataTestId = 'molecule-block-header',
  docsLink = '',
  pullBottom = false,
  title,
}) => {
  const { t } = useTranslation('common');

  return (
    <Container data-testid={dataTestId} pullBottom={pullBottom}>
      <ConditionalRender
        condition={!!docsLink}
        fallback={
          <>
            <Title>{title}</Title>
            {action}

            {children && <Description>{children}</Description>}
          </>
        }
      >
        <>
          <Stack>
            <Title>{title}</Title>
            <ConditionalRender condition={!!docsLink}>
              <PaddedContent pushLeft="xs">
                <Info tooltipPosition="right">
                  <Trans i18nKey="">
                    <>
                      <p>
                        <Link external to={docsLink} rel="nofollow" target="_blank">
                          {t('seeDocumentation')}
                        </Link>
                      </p>
                    </>
                  </Trans>
                </Info>
              </PaddedContent>
            </ConditionalRender>
          </Stack>
          {action}

          {children && <Description>{children}</Description>}
        </>
      </ConditionalRender>
    </Container>
  );
};

export default BlockHeader;
