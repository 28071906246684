import axios, { AxiosResponse } from 'axios';
import { rem } from 'polished';
import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled, { css } from 'styled-components';

import { Header, HighlightParams, HighlightResult, SearchResult, Finding } from '../types';

import { usePermissions } from '../hooks/usePermissions';

import Center from '../ui/Center';
import ConditionalRender from '../ui/ConditionalRender';
import ErrorMessage from '../ui/ErrorMessage';
import Loading from '../ui/Loading';
import NoData from '../ui/NoData';

import Highlights from './Highlights';

import type { QueryValues } from './Form';

const HEADER_CUSTOMER_ID = 'X-A1S-CUSTOMER-ID';
const HEADER_INTERNAL_SUPPORT = 'X-A1S-INTERNAL-SUPPORT';

const Image = styled.img`
  margin-top: ${(p) => rem(p.theme.spacing.lg)};
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;
  width: 90vw;
`;

const StyledTabs = styled(Tabs)`
  max-width: 95%;
`;

/*
  ------------------------- ContentViewer Component ----------------------------------------
*/

interface Props {
  formValues: QueryValues;
  renderImages: HighlightParams['renderImages'];
  row: SearchResult | null;
  showPreviewTab: boolean;
  waitSeconds: HighlightParams['waitSeconds'];
}

const ContentViewer: React.FC<Props> = ({
  formValues,
  renderImages,
  row,
  showPreviewTab,
  waitSeconds,
}): React.ReactElement => {
  const {
    permissions: { downloadPermitted },
  } = usePermissions();

  const [dataError, setDataError] = useState('');
  const [detail, setDetail] = useState<HighlightResult | null>(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { clientUuid, detectionReasons = [], findings = [], metadata = '{}', postfixIdent } = row || {};

  let parsedMetadata = {};
  try {
    parsedMetadata = JSON.parse(metadata);
  } catch (error) {
    console.warn(error.message);
  }

  const endpoint = new URL(location.origin);

  endpoint.pathname = '/highlight';

  const highlightParams: HighlightParams = {
    client_id: clientUuid || '',
    id: postfixIdent || '',
    renderImages,
    waitSeconds,
    ...formValues.endpoint?.highlightParams,
  };

  const filteredParams = Object.entries(highlightParams).filter(
    ([param]) => !formValues.endpoint?.excludeParams.includes(param as keyof HighlightParams)
  );

  for (const param in filteredParams) {
    const [name, value] = filteredParams[param];
    if (value !== undefined && value !== null) endpoint.searchParams.append(name, value.toString());
  }

  const getErrorMessage = (message: string, response: AxiosResponse) => {
    if (response?.data) {
      if (response.status === 404) {
        return `Message with postfixID ${response.data}`;
      }
      if (typeof response.data === 'string') {
        return response.data;
      }
    }
    return message;
  };

  useEffect(() => {
    const fetchPreview = async () => {
      setLoading(true);

      try {
        const { data }: { data: HighlightResult } = await axios.get(endpoint.href, {
          headers: { [HEADER_CUSTOMER_ID]: clientUuid, [HEADER_INTERNAL_SUPPORT]: 'true' },
        });
        const { errors: respError } = data;
        setDetail(data);
        if (respError) {
          setDataError(respError.toString());
        }
      } catch (err) {
        const { message, response }: { message: string; response: AxiosResponse } = err;
        setError(getErrorMessage(message, response));
      }
      setLoading(false);
    };

    fetchPreview();
  }, [clientUuid, endpoint.href]);

  const getRawDataValue = () => {
    if (detail?.raw?.length) return detail.raw;
    return dataError ? dataError : 'Raw Data Error';
  };

  if (loading) return <Loading />;

  if (error)
    return (
      <Center>
        <ErrorMessage>{`Error loading details: ${error}`}</ErrorMessage>
      </Center>
    );

  return (
    <StyledTabs>
      <TabList>
        {
          // note ConditionalRender screws up the tabs
          showPreviewTab && detail?.bodyImage && <Tab>Preview</Tab>
        }
        <Tab>Highlights</Tab>

        {downloadPermitted && <Tab>Raw</Tab>}

        <Tab>Metadata</Tab>
        <Tab>Reasons</Tab>
        {findings.length > 0 && <Tab>Findings</Tab>}
      </TabList>

      {showPreviewTab && detail?.bodyImage && (
        <TabPanel>
          <ConditionalRender condition={!!detail.headers}>
            <EmailParts headers={detail.headers || []} />
          </ConditionalRender>

          <ConditionalRender condition={!!detail.bodyImage}>
            <hr />
            <Image alt="message image" src={`data:image/png;base64, ${detail.bodyImage}`} />
          </ConditionalRender>
        </TabPanel>
      )}

      <TabPanel>{!!detail && <Highlights detail={detail} />}</TabPanel>

      {downloadPermitted && (
        <TabPanel>
          <ConditionalRender condition={!!detail?.raw} fallback={<NoData>No Raw Data to Display</NoData>}>
            <textarea className="mailtext" value={getRawDataValue()} readOnly />
          </ConditionalRender>
        </TabPanel>
      )}

      <TabPanel>
        <textarea className="mailmeta" readOnly value={JSON.stringify(parsedMetadata, null, 2)} />
      </TabPanel>

      <TabPanel>
        <div className="mailtext" dangerouslySetInnerHTML={createMarkup(getReasons(detectionReasons))} />
      </TabPanel>

      {findings.length > 0 && (
        <TabPanel>
          <Findings findings={findings} />
        </TabPanel>
      )}
    </StyledTabs>
  );
};

export default ContentViewer;

/*
  Private functions
*/

function createMarkup(theHtml: string) {
  return { __html: theHtml };
}

const getReasons = (detectionReasons: string[]) => {
  let html = detectionReasons.reduce((htmlString, reason) => `${htmlString}<li>${reason}</li><br/>`, '<ul>');
  html += '</ul>';
  return html;
};

/*
    Findings Component
*/

const Container = styled.div`
  margin-left: 20px;
`;

const CapitalizedH2 = styled.h2`
  margin: 15px 0px 5px 0px;
  text-transform: capitalize;
`;

const CapitalizedP = styled.p`
  font-family: sans-serif;
  font-size: 15px;
  margin: 2px 10px;
  text-transform: capitalize;
`;

function Findings({ findings = [] }: { findings: Finding[] }) {
  return (
    <Container>
      {findings.map((finding) => (
        <Fragment key={JSON.stringify(finding)}>
          <CapitalizedH2>{finding.name.replace(/_/g, ' ')}</CapitalizedH2>
          {Object.keys(finding)
            .sort((a, b) => a.localeCompare(b))
            .map((property) => (
              <Fragment key={property}>
                {property !== 'name' && finding[property as keyof Finding] !== null && (
                  <CapitalizedP>{`${property}: ${finding[property as keyof Finding]}`}</CapitalizedP>
                )}
              </Fragment>
            ))}
        </Fragment>
      ))}
    </Container>
  );
}

/*
    EmailParts Component
*/

const FlexDiv = styled.div`
  display: flex;
  font-size: ${rem(12)};
  padding-left: ${({ theme }) => rem(theme.spacing.sm)};
`;

const StyledP = styled.p<{ firstCol?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(12)};
  margin: ${({ theme }) => rem(theme.spacing.xs)};

  ${({ firstCol }) =>
    firstCol &&
    css`
      width: ${rem(100)};
    `};
`;

function EmailParts({ headers }: { headers: Header[] }) {
  return (
    <>
      {headers.map((header, i) => (
        <FlexDiv key={header + i.toString()}>
          <StyledP firstCol>{header.name}:</StyledP>
          <StyledP> {header.value} </StyledP>
        </FlexDiv>
      ))}
    </>
  );
}
