// -*- mode: rjsx; js2-basic-offset: 2;  -*-
import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';

import App from './App';

import './axiosConfig';

// ========================================
Modal.setAppElement('#root');
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
