import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Stack = styled.div.attrs({ 'data-testid': 'template-stack' })`
  display: flex;

  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: center;
    `};

  ${({ spaced, xl }) =>
    spaced &&
    css`
      & > :not(:first-child) {
        margin-left: ${({ theme }) => (xl ? rem(theme.spacing.xl) : rem(theme.spacing.md))};
      }
    `};
`;

Stack.displayName = 'Stack';

export default Stack;
