import { rgba } from 'polished';
// $FlowFixMe
import React, { useLayoutEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled(animated.div).attrs({ role: 'presentation' })`
  background-color: ${(p) => rgba(p.theme.colors.onyx, 0.7)};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function Backdrop({ dataTestId = 'atom-backdrop', invisible = true, ...props }) {
  const [visible, setVisible] = useState(!invisible);

  useLayoutEffect(() => {
    let timeout;
    if (invisible === true) {
      timeout = setTimeout(() => requestAnimationFrame(() => setVisible(false)), 250);
    } else {
      setVisible(true);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [invisible, setVisible]);

  const transitions = useTransition(visible, null, {
    config: { tension: 500, friction: 50, mass: 1 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions.map(
    ({ item, key, props: style }) =>
      item && <Container data-testid={dataTestId} {...props} aria-hidden={invisible} key={key} style={style} />
  );
}
