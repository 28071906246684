import axios from 'axios';
import { get } from 'lodash';
import { rem } from 'polished';
import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { useCustomers } from '../hooks/useCustomers';
import { usePermissions } from '../hooks/usePermissions';

import Alert from '../ui/Alert';
import Badge from '../ui/Badge';
import Center from '../ui/Center';
import Checkbox from '../ui/Checkbox';
import ConditionalRender from '../ui/ConditionalRender';
import ErrorMessage from '../ui/ErrorMessage';
import Loading from '../ui/Loading';
import MaxHeight from '../ui/MaxHeight';
import NoData from '../ui/NoData';
import Subtitle from '../ui/Subtitle';
import { Body, Cell, Row, Container as Table } from '../ui/Table';
import TableHeader from '../ui/TableHeader';

import PaddedContent from '../ui/atoms/PaddedContent';
import StatusIcon from '../ui/atoms/StatusIcon';

import Info from '../ui/molecules/Info';
import Timestamp from '../ui/molecules/Timestamp';

import { compare, formatUTC, pluralizeMessage } from '../utils';

import Closer from '../SearchApp/Closer';

import DetailTable from './DetailTable';
import Form from './Form';
import Menu from './RowMenu';
import MultiSelectActionsMenu from './MultiSelectActionsMenu';
import PreviewOrRetractionErrorMessage from './PreviewOrRetractionErrorMessage';
import Previews from './Previews';
import RedressActionDetail from './RedressActionDetail';
import RetractDialog from './RetractDialog';

/*
  ------------------------------styles---------------------------------------------
*/

const CheckboxDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const Explanation = styled.p`
  font-family: sans-serif;
  font-size: ${rem(16)};
  margin: ${rem(5)} ${rem(50)};
  width: 80%;
`;

const FlexDiv = styled.div`
  display: flex;
`;

const Margin = styled.div`
  margin-bottom: ${rem(15)};
`;

const StyledBadge = styled(Badge)`
  margin-top: ${({ theme }) => rem(theme.spacing.xs)};
`;

const StyledCell = styled(Cell)`
  font-size: ${rem(11)};
  height: 100%;
  padding-right: ${({ noPadding, theme }) => (noPadding ? rem(0) : rem(theme.spacing.sm))} !important;
  vertical-align: ${({ centered }) => (centered ? 'middle' : 'top')};
  word-wrap: break-word !important;
`;

/*
  ------------------- Constants ---------------------------------------------------
*/

const LIMIT = 100;

/*
  ------------------   TraceApp Component   ---------------------------------------------------
*/

export default function TraceApp() {
  const { customers } = useCustomers();

  const {
    permissions: { retractionPermitted },
  } = usePermissions();

  // preview, redressActions, and retract states
  const [alertOpen, setAlertOpen] = useState(false);
  const [clientId, setClientId] = useState('');
  const [retractErrors, setRetractErrors] = useState({ errors: [], requestsTotal: 0 });
  const [retractFromPreviewsRecipients, setRetractFromPreviewsRecipients] = useState([]);
  const [retractServerError, setRetractServerError] = useState('');
  const [retractSuccessAlertOpen, setRetractSuccessAlertOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [showPreviews, setShowPreviews] = useState(false);
  const [showRedressActions, setShowRedressActions] = useState(false);

  // table states
  const [fetchedMessages, setFetchedMessages] = useState(null);
  const [fetchedLogs, setFetchedLogs] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isExpandedLocal, setIsExpandedLocal] = useState({});
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [serverError, setServerError] = useState('');

  const messages = get(fetchedMessages, 'messages', []); // use null to only render the component once we have a response

  const messagesNoRecipients = messages.filter(({ clientRecipients }) => !clientRecipients.length);

  const responseExplained = get(fetchedMessages, 'responseExplained', '');

  useEffect(() => {
    window.addEventListener('keyup', handleClosePreview);
    return () => window.removeEventListener('keyup', handleClosePreview);
  });

  const handleClosePreview = (e) => {
    e.stopPropagation();
    if (e.key === 'Escape') {
      if (showPreviews) setShowPreviews(false);
      else if (showRedressActions) setShowRedressActions(false);
    }
  };

  const handleFormSubmit = async (values, start, end) => {
    setServerError('');
    setSelectedRows(new Set());
    const clientId = customers.find((c) => values.client === c.value.clientName).value.clientId;
    setClientId(clientId);

    // build the query params
    let query = 'limit=' + LIMIT;
    setLoadingMessages(true);
    setFetchedMessages(null);

    const queryKeys = ['alertId', 'client', 'domain', 'messageId', 'recipient', 'sender', 'subject'];

    // this adds the form vals to the query string
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (value && queryKeys.includes(key)) {
        query += `&${key}=${encodeURIComponent(value)}`;
      }
    });

    query += `&start=${start.toISOString()}&end=${end.toISOString()}`;

    try {
      setFormSubmitted(true);
      const { data: fetchedMessages } = await axios.get('/search?' + query);

      if (fetchedMessages?.messages) {
        fetchedMessages.messages.sort((a, b) => compare(b.ts, a.ts));
      }
      setFetchedMessages(fetchedMessages);
    } catch (err) {
      const errorMessage = get(err, 'response.data.error');
      const statusText = get(err, 'reponse.statusText');
      const message = get(err, 'message');

      const setErrorMessage = () => {
        if (errorMessage) return errorMessage;
        if (statusText) return statusText;
        if (message) {
          if (message === 'Network Error') {
            return 'Network Error.  You likely need to re-authenticate.';
          }
          return message;
        }
        return `Query: '${query}' Failed with an Unknown Error`;
      };

      setServerError(setErrorMessage());
    }

    setLoadingMessages(false);
  };

  const changePreviewsModalState = ({ messageSet, showModal }) => {
    setShowPreviews(showModal);
    setSelectedRows(messageSet);
  };

  const changeRedressActionsModalState = ({ index, showRedressActions }) => {
    setShowRedressActions(showRedressActions);
    setSelectedRow(index);
  };

  const Expand = ({ expand, key }) => {
    setIsExpandedLocal({ ...isExpandedLocal, [key]: expand });
  };

  const handleSingleRetract = (key) => {
    setSelectedRows(new Set([key]));
    handleRetract();
  };

  const handleRetract = () => {
    setAlertOpen(true);
  };

  const handleRetractFromPreviews = (retractRecipients) => {
    setShowPreviews(false);
    setRetractFromPreviewsRecipients(retractRecipients);
    setAlertOpen(true);
  };

  const handleRowSelectionChange = (key) => {
    const newState = new Set(selectedRows);

    if (selectedRows.has(key)) {
      newState.delete(key);
      setSelectedRows(newState);
    } else {
      newState.add(key);
      setSelectedRows(newState);
    }
  };

  const resetRetractErrors = (e) => {
    e.preventDefault();
    setRetractErrors({ errors: [], requestsTotal: 0 });
  };

  return (
    <>
      {serverError && (
        <Center>
          <ErrorMessage>{`Server Error:  ${serverError}`}</ErrorMessage>
        </Center>
      )}

      <Margin>
        <Form handleFormSubmit={handleFormSubmit} setServerError={setServerError} />
      </Margin>

      <Alert expanded onDismiss={() => setRetractSuccessAlertOpen(false)} open={retractSuccessAlertOpen}>
        <Subtitle>
          {`${selectedRows.size} messsage${pluralizeMessage(selectedRows.size, 's', '')} ${pluralizeMessage(
            selectedRows.size,
            'were',
            'was'
          )} successfully retracted`}
        </Subtitle>
      </Alert>

      <Alert
        expanded
        onDismiss={retractServerError ? () => setRetractServerError('') : (e) => resetRetractErrors(e)}
        open={!!retractErrors.errors.length || retractServerError}
        title={
          retractServerError
            ? 'No messages were retracted'
            : `${retractErrors.errors.length} of ${retractErrors.requestsTotal} messages could not be retracted`
        }
        zebraStripe
      >
        <ConditionalRender
          condition={retractServerError}
          fallback={retractErrors.errors.map((error) => (
            <PreviewOrRetractionErrorMessage error={error} key={error.message_id} />
          ))}
        ></ConditionalRender>
      </Alert>

      <ConditionalRender condition={!!messages?.length && !!selectedRows.size && alertOpen}>
        <RetractDialog
          alertOpen={alertOpen}
          handleActionCancel={() => setAlertOpen(false)}
          messages={messages}
          retractFromPreviewsRecipients={retractFromPreviewsRecipients}
          selected={selectedRows}
          setRetractErrors={setRetractErrors}
          setRetractFromPreviewsRecipients={setRetractFromPreviewsRecipients}
          setRetractServerError={setRetractServerError}
          setRetractSuccessAlertOpen={setRetractSuccessAlertOpen}
        />
      </ConditionalRender>

      <Table data-testid="trace-table" dimmed fixed spaced zebraStripes>
        <ConditionalRender condition={!!messages.length}>
          <TableHeader
            colWidths={[80, '11%', '24%', '24%', '35%', '6%', 85]}
            headings={[
              retractionPermitted && !!messages?.length && messagesNoRecipients.length !== messages?.length ? (
                <MultiSelectActionsMenu
                  changePreviewsModalState={changePreviewsModalState}
                  messages={messages}
                  messagesNoRecipients={messagesNoRecipients}
                  selectedRows={selectedRows}
                  setAlertOpen={setAlertOpen}
                  setSelectedRows={setSelectedRows}
                />
              ) : (
                ''
              ),
              'Date(UTC)/Disposition',
              'Sender',
              'Recipient(s)',
              'Subject',
              'Phish Submission',
              '',
            ]}
            textAlign={(heading) => (['Phish Submission'].includes(heading) ? 'center' : 'left')}
          />
        </ConditionalRender>

        <ConditionalRender
          condition={loadingMessages || (!loadingMessages && !serverError && messages?.length === 0 && formSubmitted)}
        >
          <Body>
            <Row />
            <Row>
              <Cell colSpan="6">{loadingMessages ? <Loading /> : <NoData message={'No Data'} />}</Cell>
            </Row>
          </Body>
        </ConditionalRender>

        <ConditionalRender condition={!loadingMessages && !serverError && !!messages?.length}>
          <Body>
            {messages?.map(
              (
                {
                  clientName,
                  clientRecipients,
                  envelopeTo,
                  finalDisposition,
                  from,
                  key,
                  phishSubmission,
                  redressedActions,
                  subject,
                  ts,
                },
                index
              ) => {
                const expanded = get(isExpandedLocal, key, null);
                const logs = get(fetchedLogs, key, null);

                return (
                  <Fragment key={key}>
                    <Row>
                      <StyledCell>
                        <ConditionalRender condition={retractionPermitted && !!clientRecipients.length} fallback="">
                          <CheckboxDiv>
                            <Checkbox checked={selectedRows.has(key)} onChange={() => handleRowSelectionChange(key)} />
                          </CheckboxDiv>
                        </ConditionalRender>
                      </StyledCell>
                      <StyledCell>
                        <FlexDiv>
                          <Timestamp date={formatUTC(ts)} />
                          <ConditionalRender condition={!!redressedActions.length}>
                            <PaddedContent pushLeft="sm">
                              <Info
                                icon="mail-retract"
                                onClick={() => changeRedressActionsModalState({ index, showRedressActions: true })}
                                tooltipPosition="right"
                              >
                                View Redress Actions
                              </Info>
                            </PaddedContent>
                          </ConditionalRender>
                        </FlexDiv>
                        <StyledBadge disposition={finalDisposition}>{finalDisposition}</StyledBadge>
                      </StyledCell>
                      <StyledCell>{from}</StyledCell>
                      <StyledCell>
                        <MaxHeight height={150}>{envelopeTo.join(', ')}</MaxHeight>
                      </StyledCell>
                      <StyledCell>{subject}</StyledCell>
                      <StyledCell centered noPadding>
                        <ConditionalRender condition={phishSubmission}>
                          <StatusIcon name="bell" noMargin small />
                        </ConditionalRender>
                      </StyledCell>
                      <StyledCell centered noPadding>
                        <Menu
                          handlePreviews={() =>
                            changePreviewsModalState({
                              messageSet: new Set([key]),
                              showModal: true,
                            })
                          }
                          handleRedressActions={() =>
                            changeRedressActionsModalState({
                              index,
                              showRedressActions: true,
                            })
                          }
                          handleRetract={() => handleSingleRetract(key)}
                          hasRedressActions={!!redressedActions.length}
                          key={key}
                          logsExpanded={isExpandedLocal[key]}
                          optionDisabled={!clientRecipients.length}
                          showDetails={() =>
                            Expand({
                              expand: isExpandedLocal[key] ? false : true,
                              key,
                            })
                          }
                        />
                      </StyledCell>
                    </Row>
                    <ConditionalRender condition={expanded}>
                      <Row>
                        <Cell colSpan="6">
                          <DetailTable
                            clientName={clientName}
                            fetchedLogs={fetchedLogs}
                            id={key}
                            logs={logs}
                            setFetchedLogs={setFetchedLogs}
                          />
                        </Cell>
                      </Row>
                    </ConditionalRender>
                  </Fragment>
                );
              }
            )}
          </Body>
        </ConditionalRender>
      </Table>

      <ConditionalRender condition={!!responseExplained}>
        <Explanation>{responseExplained}</Explanation>
      </ConditionalRender>

      <Modal isOpen={showRedressActions}>
        <Closer
          onClose={() =>
            changeRedressActionsModalState({
              index: null,
              showRedressActions: false,
            })
          }
        />
        <ConditionalRender condition={selectedRow !== null}>
          <RedressActionDetail redressActions={selectedRow !== null && messages[selectedRow].redressedActions} />
        </ConditionalRender>
      </Modal>

      <Modal isOpen={showPreviews}>
        <Closer
          onClose={() => {
            changePreviewsModalState({ messageSet: new Set(), showModal: false });
          }}
        />
        <ConditionalRender condition={selectedRows.size > 0}>
          <Previews
            clientId={clientId}
            handleRetract={handleRetractFromPreviews}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            messages={messages}
          />
        </ConditionalRender>
      </Modal>
    </>
  );
}
