import axios from 'axios';
import React, { useState } from 'react';

import { usePermissions } from '../hooks/usePermissions';

import ConditionalRender from '../ui/ConditionalRender';
import Loading from '../ui/Loading';
import PopupMenu, { Group, Option } from '../ui/PopupMenu';

import { SearchResult } from '../types';

import { emailViewHandler } from '.';

interface Props {
  handleEmailView: emailViewHandler;
  index: number;
  row: SearchResult;
}

const RowMenu: React.FC<Props> = ({ handleEmailView, index, row }): React.ReactElement => {
  const {
    permissions: { quarantineReleasePermitted },
  } = usePermissions();

  const [loading, setLoading] = useState(false);

  const handleQuarantineRelease = async () => {
    const { clientUuid, envelopeTo, storedAt } = row;

    const request = {
      request: [{ message_path: storedAt, envelope_to: envelopeTo }],
      requester: 'UNSET@area1security.com',
    };

    const recipients = envelopeTo.join(', ');
    const send = window.confirm(`Do you really want to send this message to ${recipients}?`);
    if (send) {
      setLoading(true);
      try {
        const { data } = await axios.post(`/release?client_id=${clientUuid}`, request);
        alert(data.error ? `Error sending message:\n  ${data.error}` : 'Message sent.');
      } catch (error) {
        alert(error.message);
        console.warn(`Quarantine release error: ${error.message}`);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <PopupMenu dataTestId="row-menu">
        <Group>
          <Option
            data-testid="preview"
            icon="preview"
            onClick={() => handleEmailView({ row, renderImages: true, index })}
          >
            Preview
          </Option>
        </Group>

        <Group>
          <Option icon="search" onClick={() => handleEmailView({ row, renderImages: false, index })}>
            Raw
          </Option>
        </Group>

        <ConditionalRender condition={quarantineReleasePermitted && row.isQuarantined}>
          <Group>
            <ConditionalRender condition={!loading} fallback={<Loading small />}>
              <Option icon="reload" onClick={handleQuarantineRelease}>
                Quarantine Release
              </Option>
            </ConditionalRender>
          </Group>
        </ConditionalRender>
      </PopupMenu>
    </>
  );
};

export default RowMenu;
