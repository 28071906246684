import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import DateRangePicker from './DateRangePicker';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Label = styled.label`
  flex-shrink: 0;
  font-family: sans-serif;
  font-size: ${rem(14)};
  font-weight: bold;
  margin-right: 20px;
  margin-top: 10px;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type DateTimeChangeCallback = (dateRange: Date[]) => void;

interface Props {
  dateRange: Date[];
  name: string;
  onChange: DateTimeChangeCallback;
}
const DateTimeSelector: React.FC<Props> = ({ dateRange, onChange }): React.ReactElement => {
  function handleDateRangePickerChange(dateRange: Date[]) {
    if (onChange && Array.isArray(dateRange)) {
      onChange(dateRange);
    }
  }

  return (
    <>
      <Label>Date Range:</Label>
      <DateRangePicker onChange={handleDateRangePickerChange} value={dateRange} />
    </>
  );
};

export default DateTimeSelector;
