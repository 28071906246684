// $FlowFixMe
import React, { forwardRef } from 'react';

import Container from './Container';
import Icon from './Icon';

function StatusIcon({ dataTestId = 'atom-status-icon', name, noMargin = false, small = false, ...rest }, ref) {
  return (
    <Container data-testid={dataTestId} noMargin={noMargin} ref={ref} {...rest}>
      <Icon name={name} small={small} />
    </Container>
  );
}

export default forwardRef(StatusIcon);
