import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import OpenerIcon from './OpenerIcon';

//
// Styled Components
// -------------------------------------------------------------------------------------------------

const Button = styled.button.attrs({ 'aria-haspopup': 'true' })`
  align-items: center;
  background: transparent;
  border: 0;
  cursor: ${(p) => (p.name === 'more' ? 'auto' : 'pointer')};
  display: flex;
  height: ${rem(32)};
  justify-content: center;
  margin: 0;
  outline: 0;
  padding: 0;
  width: ${rem(32)};
  z-index: 2;
`;

const Wrapper = styled.span`
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: ${rem(12)};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.roboto};
  height: ${rem(24)};
  justify-content: center;
  margin: 0;
  outline: 0;
  padding: 0;
  transition: background-color 250ms ${({ theme }) => theme.timing.easeOutCirc};
  width: ${rem(24)};
  z-index: 2;

  path {
    transition: fill 250ms ${({ theme }) => theme.timing.easeOutCirc};
  }

  button:hover & {
    background-color: ${({ reverseContrast, theme }) =>
      reverseContrast ? theme.colors.white : theme.colors.antiFlashWhite};
  }

  button[aria-expanded='true'] & {
    background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  }

  button[aria-expanded='true'] & path {
    fill: ${({ theme }) => theme.colors.deepSkyBlue};
  }
`;

//
// Main Component
// -------------------------------------------------------------------------------------------------

export default function Opener({ expanded, menuId, name, opener, reverseContrast, ...rest }) {
  return (
    <Button aria-controls={menuId} aria-expanded={String(expanded)} name={name || String(opener)} {...rest}>
      <Wrapper reverseContrast={reverseContrast}>
        <OpenerIcon className="opener" opener={opener} />
      </Wrapper>
    </Button>
  );
}
