import styled, { css } from 'styled-components';

export default styled.div`
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.lightGray};
      & * {
        color: ${({ theme }) => theme.colors.lightGray} !important;
      }
    `};
`;
