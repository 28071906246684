import axios from 'axios';
import { Formik } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useCustomers } from '../hooks/useCustomers';

import Button from '../ui/Button';
import Center from '../ui/Center';
import Checkbox from '../ui/Checkbox';
import Dropdown from '../ui/Dropdown';
import ErrorMessage from '../ui/ErrorMessage';
import Field from '../ui/Field';
import Form from '../ui/Form';
import Loading from '../ui/Loading';
import Title from '../ui/Title';

const Container = styled.div`
  margin: 50px;
`;

const FlexDiv = styled.div`
  display: flex;
  margin-top: 25px;
`;

const StyledField = styled(Field)`
  align-self: start;
  margin: 0px;
`;

export default function FindingsConfig() {
  const { customers, error: customersError, loading: customersLoading } = useCustomers();

  const [findingsLoading, setFindingsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const getConfig = async (selectedCustomer, setFieldValue) => {
    const { clientId } = selectedCustomer.value;
    setFindingsLoading(true);

    try {
      const { data } = await axios.get(`/config?listFindings`);
      const { data: findingNamesData } = await axios.get(`/config?findingNames&client_id=${clientId}`);

      const respOptions = get(data, 'data', []);
      const respFindings = get(findingNamesData, `data`, []);

      setFieldValue('findings', setFindingsData(respOptions, respFindings));
    } catch (error) {
      setServerError(error.message);
    }

    setFindingsLoading(false);
  };

  const handleCustomerSection = (selected, setFieldValue) => {
    setFieldValue('customer', selected);
    getConfig(selected, setFieldValue);
  };

  const handleFormSubmit = ({ findings, customer }) => {
    setFindingsLoading(true);

    const { clientId } = customer.value;

    // findings looks like {finding1: true, finding2: false, etc}
    // nameString is an array of finding names that are true in the object findings
    const namesString = Object.keys(findings)
      .reduce((names, name) => `${names}${findings[name] ? `${name},` : ''}`, '')
      .slice(0, -1); // remove the trailing comma

    try {
      axios.get(`/config?findingNames=${namesString}&clientId=${clientId}`);
    } catch (error) {
      setServerError(error.message || 'Error setting findings');
    }

    setFindingsLoading(false);
  };

  const handleCheckboxChange = (name, findings, setFieldValue) => {
    setFieldValue('findings', { ...findings, [name]: !findings[name] });
  };

  const initialValues = {
    findings: {},
  };

  if (customersLoading) return <Loading />;

  if (customersError || !customers.length)
    return (
      <Center>
        <ErrorMessage>Error loading customers. Possibly refresh.</ErrorMessage>
      </Center>
    );

  return (
    <Container>
      <Title>Findings Configuration</Title>

      {serverError && (
        <Center>
          <ErrorMessage>{`Server Error:  ${serverError}`}</ErrorMessage>
        </Center>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleFormSubmit(values)}
        validateOnChange={false}
      >
        {(props) => {
          const { handleSubmit, setFieldValue, values } = props;
          const { findings, customer } = values;

          return (
            <Form onSubmit={handleSubmit}>
              {!!customers.length && (
                <>
                  <Field label="Select A Customer">
                    <Dropdown
                      isClearable={true}
                      id="customer"
                      isSearchable={true}
                      name="customer"
                      onChange={(selected) => handleCustomerSection(selected, setFieldValue)}
                      options={customers}
                      value={customer}
                    />
                  </Field>

                  {findingsLoading && <Loading />}

                  {!!Object.keys(findings).length && (
                    <FlexDiv>
                      <StyledField label="Findings Names" />
                      <div>
                        {Object.keys(findings).map((name) => (
                          <Checkbox
                            block={true}
                            checked={findings[name]}
                            key={name}
                            label={name}
                            name={name}
                            onClick={() => handleCheckboxChange(name, findings, setFieldValue)}
                          />
                        ))}
                      </div>
                    </FlexDiv>
                  )}
                  <Button disabled={findingsLoading} onClick={handleSubmit} type="submit">
                    Submit
                  </Button>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}

/*
    Private functions
*/

const setFindingsData = (options, findings) =>
  options.reduce((initVal, option) => ({ ...initVal, [option]: findings.includes(option) }), {});
