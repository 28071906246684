import React from 'react';

import { Col, Head, Header, Row } from '../Table';

type ColWidth = string | number;

interface Props {
  colWidths?: ColWidth[];
  dataTestId?: string;
  headings?: string[];
  textAlign?: string | ((heading: string) => string);
}

const TableHeader: React.FC<Props> = ({
  colWidths = [],
  dataTestId = 'molecule-table-header',
  headings = [],
  textAlign,
}): React.ReactElement => {
  const alignHeading = (heading: string) => {
    if (!textAlign) return 'left';

    if (typeof textAlign === 'function') {
      return textAlign(heading);
    }
    return textAlign;
  };

  const renderCols = (widths: ColWidth[]) =>
    widths.length ? (
      // eslint-disable-next-line react/no-array-index-key
      widths.map((width, i) => <Col key={width.toString() + i} width={width} />)
    ) : (
      <>
        {
          // eslint-disable-next-line no-unused-vars, react/no-array-index-key
          [...new Array(headings.length).map((col, i) => <Col key={i} />)]
        }
      </>
    );

  const renderHeadings = () =>
    headings.map((heading, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Header textAlign={alignHeading(heading)} key={heading + i}>
        {heading}
      </Header>
    ));

  return (
    <>
      <colgroup>{renderCols(colWidths)}</colgroup>
      <Head data-testid={dataTestId}>
        <Row>{renderHeadings()}</Row>
      </Head>
    </>
  );
};

export default TableHeader;
