import React from 'react';

import { ReactComponent as More } from './icons/more.svg';
import { ReactComponent as User } from './icons/user.svg';

const OpenerIcon = ({ opener, ...props }) => {
  // If this is an object it's an already "called" component so just render it as-is
  if (typeof opener === 'object') return opener;

  switch (opener) {
    case 'more':
      return <More {...props} />;
    case 'user':
      return <User {...props} />;
    default:
      return null;
  }
};

export default OpenerIcon;
