import { meetsContrastGuidelines, rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.colors.topaz, 0.3)};
  border-radius: ${rem(4)};
  display: inline-flex;
  grid-template-columns: ${rem(48)} 1fr;
  padding: ${({ theme }) => rem(theme.spacing.xs)};

  ${({ disposition, theme }) =>
    disposition &&
    css`
      background-color: ${theme.colors[translateDisposition(disposition)]};

      & > span:first-child {
        color: ${setTextColor(disposition, theme)};
      }
    `};

  ${({ detectionMiss, theme }) =>
    detectionMiss &&
    css`
      background-color: ${theme.colors.noDisposition};

      & > span:first-child {
        color: ${theme.colors.white};
      }
    `};
`;

export default Container;

function translateDisposition(disposition) {
  if (disposition.toLowerCase() === 'malicious-bec') return 'maliciousBec';
  if (disposition.toLowerCase() === 'none') return 'noDisposition';

  return disposition.toLowerCase();
}

function setTextColor(disposition, theme) {
  if (meetsContrastGuidelines(theme.colors[translateDisposition(disposition)], theme.colors.white).AALarge) {
    return theme.colors.white;
  }
  return theme.colors.raisinBlack;
}
