import { camelCase, transformKeys } from '@a1s/lib';
import axios from 'axios';
import { get } from 'lodash';
import { rem } from 'polished';
import React, { useMemo, useState } from 'react';

import styled from 'styled-components';

import { pluralizeMessage } from '../utils';

import { useCustomers } from '../hooks/useCustomers';

import AlertDialog from '../ui/atoms/AlertDialog';
import PaddedContent from '../ui/atoms/PaddedContent';

import Radio from '../ui/Radio';
import Subtitle from '../ui/Subtitle';
import TextStyle from '../ui/TextStyle';
import ConditionalRender from '../ui/ConditionalRender';

const MarginedSubtitle = styled(Subtitle)`
  margin-bottom: ${({ theme }) => rem(theme.spacing.lg)};
  word-wrap: break-word;
`;

export default function RetractDialog({
  alertOpen,
  handleActionCancel,
  messages = [],
  selected,
  retractFromPreviewsRecipients,
  setRetractFromPreviewsRecipients,
  setRetractServerError,
  setRetractErrors,
  setRetractSuccessAlertOpen,
}) {
  const { customers } = useCustomers();

  const [destination, setDestination] = useState('Inbox');
  const [loading, setLoading] = useState(false);

  const clientId = customers.find((customer) => customer.value.clientName === messages[0].clientName).value.clientId;

  let requests = [];
  const reqMessages = useMemo(
    () => [...selected].map((key) => messages.find((message) => message.key === key)),
    [messages, selected]
  );
  reqMessages.forEach((req) => {
    // one request for every valid message recipient
    req.clientRecipients.forEach((recipient) => {
      /* eslint-disable camelcase */
      requests = [
        ...requests,
        {
          destination,
          message_id: req.messageId,
          properties: { postfix_ident: req.postfixIdent, requesting_service: 'sherlock' },
          recipient,
        },
      ];
      /* eslint-enable camelcase */
    });
  });

  const handleRetractErrors = (responses) => {
    const retractErrors = responses
      .map((response) => transformKeys(response, camelCase))
      .filter(({ status }) => !['OK', 'SUCCESS'].includes(status.toUpperCase()));
    if (retractErrors) setRetractErrors({ errors: retractErrors, requestsTotal: responses.length });
    else setRetractSuccessAlertOpen(true);
  };

  const handleRetractRequest = async () => {
    setLoading(true);
    const payload = {
      client_id: clientId,
      requests,
    };

    try {
      const { data } = await axios.post('/retract', payload);
      const responses = get(data, 'responses', []);
      handleRetractErrors(responses);
    } catch (error) {
      const { response = {} } = error;
      const responses = get(response, 'data.responses', []);

      if (responses.length) {
        handleRetractErrors(responses);
      } else {
        setRetractServerError(get(response, 'statusText', 'Request failed with an Unknown Error'));
      }
    }
    setLoading(false);
    setDestination('Inbox');
    setRetractFromPreviewsRecipients([]);
    handleActionCancel();
  };

  const retractRecipientsString =
    retractFromPreviewsRecipients.reduce(
      (acc, recipient, i) => `${acc}${acc && i < retractFromPreviewsRecipients.length ? ', ' : ''}${recipient}`,
      ''
    ) + '.';

  return (
    <>
      <AlertDialog
        busy={!destination || loading}
        buttonText={<span>{`Retract ${selected.size} message${pluralizeMessage(selected.size, 's', '')}`}</span>}
        message={
          <>
            <ConditionalRender condition={!!retractFromPreviewsRecipients.length}>
              <MarginedSubtitle>{`The message will be retracted for the following recipients: ${retractRecipientsString}`}</MarginedSubtitle>
            </ConditionalRender>
            <Subtitle>{`Select a destination folder for the message${pluralizeMessage(
              selected.size,
              's',
              ''
            )}`}</Subtitle>
            <PaddedContent pushBottom="lg" pushTop="md" style={{ textAlign: 'left' }}>
              {['Inbox', 'JunkEmail', 'DeletedItems', 'RecoverableItemsDeletions', 'RecoverableItemsPurges'].map(
                (action) => (
                  <Radio
                    key={action}
                    checked={action === destination}
                    label={humanReadableActions[action]}
                    onChange={() => setDestination(action)}
                    value={action}
                  />
                )
              )}
            </PaddedContent>
            <TextStyle warning>THIS ACTION CANNOT BE UNDONE AND MAY TAKE UP TO 30 SECONDS TO COMPLETE</TextStyle>
          </>
        }
        onActionCancel={handleActionCancel}
        onActionConfirm={handleRetractRequest}
        open={alertOpen}
        zebraStripes
      />
    </>
  );
}

/*
  Private functions and vars
*/

const humanReadableActions = {
  Inbox: 'Inbox',
  JunkEmail: 'Junk Email',
  DeletedItems: 'Trash',
  RecoverableItemsDeletions: 'Soft Delete (User Recoverable)',
  RecoverableItemsPurges: 'Hard Delete (Admin Recoverable)',
};
