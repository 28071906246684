import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import Label from './Label';

const Container = ({ children, className, dataTestId = 'molecule-field', info, label, labelFor }) => (
  <div className={className} data-testid={dataTestId}>
    {info && info}
    {label && <Label htmlFor={labelFor}>{label}</Label>}
    {children}
  </div>
);

const Field = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: ${rem(16)};

  ${({ label }) =>
    !label &&
    css`
      padding-left: ${rem(16)};
    `};

  /* stylelint-disable-next-line */
  ${Label} {
    flex-shrink: 0;
    margin-right: ${rem(16)};
    min-width: ${({ info }) => (info ? rem(125) : rem(140))};
    max-width: ${({ info }) => (info ? rem(125) : rem(140))};
  }

  ${({ stacked }) =>
    stacked &&
    css`
      flex-direction: column;
      justify-content: flex-start;
      width: ${rem(400)};

      /* stylelint-disable-next-line */
      ${Label} {
        align-self: flex-start;
        margin-bottom: ${rem(4)};
        width: auto;
      }
    `};
`;

Field.displayName = 'Field';

export default Field;
