import React from 'react';
import shortid from 'shortid';

import DisabledContainer from '../DisabledContainer';

import Container from './Container';
import Input from './Input';
import Label from './Label';

const Radio = ({
  dataTestId = 'atom-radio',
  disabled = false,
  disabledTooltipContents,
  id = shortid.generate(),
  label,
  block = false,
  ...props
}) => (
  <Container block={block} data-testid={dataTestId}>
    <DisabledContainer disabled={disabled} tooltipContents={disabledTooltipContents}>
      <Input disabled={disabled} id={id} {...props} />
      <Label disabled={disabled} htmlFor={id}>
        {label}
      </Label>
    </DisabledContainer>
  </Container>
);

export default Radio;
