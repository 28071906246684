import axios from 'axios';
import { get } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { camelCase, transformKeys } from '@a1s/lib';

import { sortAnnotate } from '../utils';

import { Customer } from '../types';

interface CustomerProviderContext {
  customers: Customer[];
  error: string | undefined;
  loading: boolean;
}

const Context = createContext<CustomerProviderContext>({
  customers: [],
  error: undefined,
  loading: false,
});

export function useCustomers(): CustomerProviderContext {
  return useContext(Context);
}

const CustomersProvider: React.FC<React.ReactNode> = ({ children }): React.ReactElement => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchCustomers() {
      try {
        const { data }: { data: Customer[] } = await axios.get('/customers');
        setCustomers(sortAnnotate(data).map((customer: Customer) => transformKeys(customer, camelCase)) as Customer[]);
      } catch (error) {
        setError(get(error, 'message', 'Error Fetching Customers'));
      }
      setLoading(false);
    }

    if (!customers.length) fetchCustomers();
  }, [customers.length, setCustomers]);

  return (
    <Context.Provider
      value={{
        error,
        loading,
        customers,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { CustomersProvider };
