import { endOfDay, startOfDay, subDays } from 'date-fns';
import { Formik } from 'formik';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useCustomers } from '../hooks/useCustomers';

import Button from '../ui/Button';
import Center from '../ui/Center';
import DateTimeSelector from '../ui/DateTimeSelector';
import Dropdown from '../ui/Dropdown';
import ErrorMessage from '../ui/ErrorMessage';
import Instructions from '../ui/Instructions';
import Label from '../ui/Label';
import Loading from '../ui/Loading';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const Inline = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
  width: 20%;

  ${({ large }) =>
    large &&
    css`
      width: 50%;
    `};
`;

const InlineContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 5px 50px;
  width: 90%;
`;

const Input = styled.textarea`
  font-family: sans-serif;
  font-size: 15px;
  margin: 0px 20px 0px 10px;
  text-indent: 10px;
  width: 80%;
`;

const SubjectInput = styled.input`
  font-family: sans-serif;
  font-size: 15px;
  margin: 0px 20px 0px 10px;
  text-indent: 10px;
  width: 80%;
`;

const DEFAULT_CUSTOMER = 'area1';
const DEFAULT_INITIAL_DATE_INTERVAL = 30;

//
// Form Component
// --------------------------------------------------------------------------------------

export default function TraceForm({ handleFormSubmit, setServerError }) {
  const { customers, error: customersError, loading: customersLoading } = useCustomers();

  const [dateRange, setDateRange] = useState([
    startOfDay(subDays(new Date(), DEFAULT_INITIAL_DATE_INTERVAL)),
    endOfDay(new Date()),
  ]);
  const [open, setOpen] = useState(false);

  if (customersLoading) return <Loading />;

  const [start, end] = dateRange;

  const initialFormValues = {
    alertId: '',
    client: DEFAULT_CUSTOMER,
    domain: '',
    recipient: '',
    selectedCustomer: customers.find((c) => c.value.clientName === DEFAULT_CUSTOMER),
    sender: '',
    subject: '',
    messageId: '',
  };

  const togglePanel = () => setOpen(!open);

  const onEnterPress = (e, handleSubmit) => {
    if (e.key === 'Enter') handleSubmit();
  };

  const onFormChange = (value, formField, setFieldValue) => {
    setServerError('');
    setFieldValue(formField, value.replace(/\n/g, ''));
  };

  if (customersError || !customers.length)
    return (
      <Center>
        <ErrorMessage>
          Error loading customers. Try refreshing the browser to reload the data. Contact engineering if the problem
          persists.
        </ErrorMessage>
      </Center>
    );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={(values) => handleFormSubmit(values, start, end)}
        validate={(values) => validate(values, start, end)}
        validateOnChange={false}
      >
        {(props) => {
          const { errors, handleSubmit, setErrors, setFieldValue, values } = props;
          const { alertId, domain, messageId, recipient, selectedCustomer, sender, subject } = values;

          return (
            <form onSubmit={handleSubmit}>
              <Column>
                {!!Object.keys(errors).length && (
                  <InlineContainer>
                    {Object.keys(errors).map((key) => (
                      <ErrorMessage key={key}>{errors[key]}</ErrorMessage>
                    ))}
                  </InlineContainer>
                )}

                <InlineContainer>
                  <Inline>
                    <Label htmlFor="customer">Customer:</Label>
                    <Dropdown
                      isClearable={true}
                      id="customer"
                      isSearchable={true}
                      name="selectedCustomer"
                      onChange={(selected) => {
                        setErrors({});
                        setServerError('');
                        setFieldValue('selectedCustomer', selected);
                        setFieldValue('client', selected ? selected.value.clientName : DEFAULT_CUSTOMER);
                      }}
                      options={customers}
                      value={selectedCustomer}
                    />
                  </Inline>

                  <Inline large>
                    <Label htmlFor="subject">Subject (substring):</Label>
                    <SubjectInput
                      id="subject"
                      name="subject"
                      onChange={(value) => onFormChange(value.target.value, 'subject', setFieldValue)}
                      type="text"
                      value={subject}
                    />
                  </Inline>

                  <Inline>
                    <Button onClick={() => togglePanel()} type="button">
                      Expand Search Fields +
                    </Button>
                  </Inline>
                </InlineContainer>

                {open && (
                  <>
                    <InlineContainer>
                      <Inline>
                        <Label htmlFor="from">From:</Label>
                        <Input
                          id="from"
                          name="sender"
                          onChange={(value) => onFormChange(value.target.value, 'sender', setFieldValue)}
                          onKeyDown={(e) => onEnterPress(e, handleSubmit)}
                          type="text"
                          value={sender}
                        />
                      </Inline>

                      <Inline>
                        <Label htmlFor="to">To:</Label>
                        <Input
                          id="to"
                          name="recipient"
                          onChange={(value) => onFormChange(value.target.value, 'recipient', setFieldValue)}
                          onKeyDown={(e) => onEnterPress(e, handleSubmit)}
                          type="text"
                          value={recipient}
                        />
                      </Inline>

                      <Inline>
                        <Label htmlFor="message-id">Message ID:</Label>
                        <Input
                          id="message-id"
                          name="messageId"
                          onChange={(value) => onFormChange(value.target.value, 'messageId', setFieldValue)}
                          onKeyDown={(e) => onEnterPress(e, handleSubmit)}
                          type="text"
                          value={messageId}
                        />
                      </Inline>

                      <Inline>
                        <Label>Alert ID:</Label>
                        <Input
                          type="text"
                          name="alertId"
                          value={alertId}
                          onChange={(value) => onFormChange(value.target.value, 'alertId', setFieldValue)}
                          onKeyDown={(e) => onEnterPress(e, handleSubmit)}
                        />
                      </Inline>

                      <Inline>
                        <Label htmlFor="domain">Domain:</Label>
                        <Input
                          id="domain"
                          name="domain"
                          onChange={(value) => onFormChange(value.target.value, 'domain', setFieldValue)}
                          onKeyDown={(e) => onEnterPress(e, handleSubmit)}
                          type="text"
                          value={domain}
                        />
                      </Inline>
                    </InlineContainer>

                    <Instructions>
                      Click on either the start or end date. Then click on a start date and an end date in the dropdown
                      calendar to choose a new period. Or simply choose from the quick days back selections.
                    </Instructions>

                    <InlineContainer>
                      <DateTimeSelector
                        dateRange={dateRange}
                        name="dateRange"
                        onChange={(dateRange) => {
                          setErrors({});
                          setDateRange(dateRange);
                        }}
                      />
                    </InlineContainer>
                  </>
                )}

                <InlineContainer>
                  <Button onClick={handleSubmit} type="submit">
                    Submit
                  </Button>
                </InlineContainer>
              </Column>
            </form>
          );
        }}
      </Formik>
    </>
  );
}

/*
  Private functions
*/

const validate = ({ client, selectedCustomer }, start, end) => {
  const errors = {};
  if (end < start) errors.date = 'The end date cannot be before the start date';
  if (!client || !selectedCustomer) errors.customer = 'You must select a customer from the dropdown.';
  return errors;
};
