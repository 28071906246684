import { rem } from 'polished';
import styled from 'styled-components';

const Group = styled.div`
  padding: ${({ theme }) => rem(theme.spacing.sm)} 0;

  & + & {
    border-top: ${({ theme }) => theme.colors.glitter} solid ${rem(1)};
  }
`;

Group.displayName = 'Group';

export default Group;
