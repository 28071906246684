import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import ConditionalRender from '../ui/ConditionalRender';
import Subtitle from '../ui/Subtitle';

import {
  Attachment,
  Brand,
  Envelope,
  HighlightResult,
  HighlightKey,
  Link,
  MaliciousFinding,
  NewDomainReference,
  OcrContent,
  Sentiment,
  Spoof,
  ThreatCategory,
  Validation,
} from '../types';

const BoldSubtitle = styled(Subtitle)`
  font-weight: 600;
`;

const CapSubtitle = styled(BoldSubtitle)`
  text-transform: capitalize;
`;

const Col2Row = styled.div<{ marginBottom?: boolean; noPadding?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 85%;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${rem(5)};
    `};
`;

// FlexRow and Text are out of alpha order b/c they are referenced in FlexCol
const FlexRow = styled.div<{ noPadding?: boolean; zebraStripes?: boolean }>`
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => rem(theme.spacing.md)};
  width: 98%;

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: ${rem(0)};
    `};

  ${({ theme, zebraStripes }) =>
    zebraStripes &&
    css`
      &:nth-child(odd) {
        background-color: ${theme.colors.ghostWhite};
      }
    `};
`;

const Text = styled.p<{ bold?: boolean; noPadding?: boolean }>`
  align-self: start;
  font-size: ${rem(14)};
  margin: 0;
  width: 80%;
  word-break: break-word;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 600;
    `};
`;

const FlexCol = styled.div`
  align-items: flex-start;
  align-self: start;
  display: flex;
  flex-direction: column;
  width: 80%;

  ${FlexRow}:not(:last-child) {
    margin-bottom: ${({ theme }) => rem(theme.spacing.sm)};
  }

  ${Text}:not(:last-child) {
    margin-bottom: ${({ theme }) => rem(theme.spacing.sm)};
  }
`;

/*
    ---------------------------- Highlights component ------------------------------------
*/

interface Props {
  detail: HighlightResult;
}

const Highlights: React.FC<Props> = ({ detail }): React.ReactElement => {
  const renderObjectHighlights = (key: HighlightKey, detail: HighlightResult[HighlightKey]): React.ReactElement => {
    switch (key) {
      case 'senderInfo':
      case 'validation':
        return renderObject(key as HighlightKey, detail as Validation);
      case 'envelopeTo':
        return renderArray(detail as Envelope[]);
      case 'brands':
      case 'maliciousFindings':
      case 'sentiments':
        return renderList(detail as Brand[] | MaliciousFinding[] | Sentiment[]);
      case 'attachments':
      case 'newDomainReferences':
        return renderObjectArray(key as HighlightKey, detail as Attachment[] | NewDomainReference[]);
      case 'threatCategories':
        return renderThreatCategories(detail as ThreatCategory[]);
      case 'ocrContent':
        return renderText(detail as OcrContent);
      case 'links':
        return renderObjectInline(key as HighlightKey, detail as Link[], ['text', 'href']);
      case 'spoofs':
        return renderSpoofs(key as HighlightKey, detail as Spoof[]);
      default:
        return <div>detail</div>;
    }
  };

  return (
    <>
      {highlightKeys.map((key: HighlightKey, index: number) => {
        return (
          <FlexRow key={key + index} zebraStripes>
            <ConditionalRender
              condition={!!highlightTitleTranslations[key]}
              fallback={<CapSubtitle>{key}</CapSubtitle>}
            >
              <BoldSubtitle>{highlightTitleTranslations[key] && highlightTitleTranslations[key]}</BoldSubtitle>
            </ConditionalRender>
            <ConditionalRender
              condition={typeof detail[key] === 'string'}
              fallback={renderObjectHighlights(key, detail[key as HighlightKey])}
            >
              <Text>{detail[key] as string}</Text>
            </ConditionalRender>
          </FlexRow>
        );
      })}
    </>
  );
};

export default Highlights;

/*
   ------------------------- Private functions --------------------------------------

*/

const highlightKeys: HighlightKey[] = [
  'action',
  'senderInfo',
  'envelopeFrom',
  'envelopeTo',
  'validation',
  'maliciousFindings',
  'newDomainReferences',
  'attachments',
  'spoofs',
  'brands',
  'sentiments',
  'threatCategories',
  'links',
  'ocrContent',
];

const highlightTitleTranslations: { [Property in keyof HighlightResult]?: string } = {
  action: 'Delivery',
  attachments: 'Attachments',
  senderInfo: 'Sender',
  envelopeFrom: 'Envelope From',
  envelopeTo: 'Envelope To',
  maliciousFindings: 'Malicious Findings',
  newDomainReferences: 'Newly Registered Domain References',
  threatCategories: 'Threat Categories',
  ocrContent: 'Content Extracted From Images',
};

type Translations = {
  [Property in keyof HighlightResult]?: {
    [key: string]: string;
  };
};

const infoTranslations: Translations = {
  attachments: {
    name: 'Name',
    size: 'Size',
    contentType: 'Type',
  },
  links: {
    text: 'Text',
    href: 'URL',
  },
  newDomainReferences: {
    ageDays: 'Age',
    domain: 'Domain',
    disposition: 'Implied Disposition',
  },
  senderInfo: {
    ip: 'IP',
    asName: 'As Name',
    asNumber: 'As Number',
    geo: 'Geographic Location',
    pld: 'Paid-level Domain',
  },
  spoofs: {
    label: 'Shown',
    value: 'Actual',
  },
  validation: {
    comment: '',
    dkim: 'DKIM',
    dmarc: 'DMARC',
    spf: 'SPF',
  },
};

const renderArray = (detail: Envelope[]) => {
  if (detail === null) detail = [];
  return (
    <ConditionalRender condition={!!detail.length} fallback={<Text>None</Text>}>
      <Text>{detail.join(', ')}</Text>
    </ConditionalRender>
  );
};

const renderList = (detail: Brand[] | MaliciousFinding[] | Sentiment[]) => (
  <ConditionalRender condition={!!detail.length} fallback={<Text>None</Text>}>
    <FlexCol>
      {detail.map((item: Brand | MaliciousFinding | Sentiment) => (
        <Text key={item}>{item}</Text>
      ))}
    </FlexCol>
  </ConditionalRender>
);

const renderObject = (type: HighlightKey, detail: Validation) => (
  <ConditionalRender condition={!!detail} fallback={<Text>None</Text>}>
    <FlexCol>
      {Object.keys(detail).map((item) => (
        <FlexRow key={item} noPadding>
          <BoldSubtitle>{(infoTranslations[type] || {})[item] || ''}</BoldSubtitle>
          <Text>{detail[item as keyof Validation]}</Text>
        </FlexRow>
      ))}
    </FlexCol>
  </ConditionalRender>
);

const renderObjectArray = (type: HighlightKey, detail: Attachment[] | NewDomainReference[]) => (
  <ConditionalRender condition={!!detail.length} fallback={<Text>None</Text>}>
    <FlexCol>
      {detail.map((item) => (
        <>
          {Object.keys(item).map((e, i) => (
            <ConditionalRender
              condition={!!item[e as keyof (Attachment | NewDomainReference)]}
              key={JSON.stringify(item) + i.toString()}
            >
              <Col2Row key={JSON.stringify(item) + 'col2' + i.toString()} marginBottom noPadding>
                <BoldSubtitle>{(infoTranslations[type] || {})[e] || ''}</BoldSubtitle>
                <Text>{item[e as keyof (Attachment | NewDomainReference)] as string}</Text>
              </Col2Row>
            </ConditionalRender>
          ))}
        </>
      ))}
    </FlexCol>
  </ConditionalRender>
);

const renderObjectInline = (type: HighlightKey, detail: Link[], fields: Array<keyof Link>) => (
  <ConditionalRender condition={!!detail.length} fallback={<Text>None</Text>}>
    <FlexCol>
      {detail.map((item) => (
        <Col2Row key={JSON.stringify(item)} marginBottom noPadding>
          {fields.map((field, i) => (
            <ConditionalRender condition={i === 0} fallback={<Text>{item[field]}</Text>} key={field}>
              <BoldSubtitle>{item[field] || 'No Description'}</BoldSubtitle>
            </ConditionalRender>
          ))}
        </Col2Row>
      ))}
    </FlexCol>
  </ConditionalRender>
);

const renderText = (detail: OcrContent) => (
  <ConditionalRender condition={!!detail?.length} fallback={<Text>None</Text>}>
    <Text>{detail}</Text>
  </ConditionalRender>
);

const renderThreatCategories = (detail: ThreatCategory[]) => {
  if (detail === null) detail = [];
  return (
    <ConditionalRender condition={!!detail.length} fallback={<Text>None</Text>}>
      <FlexCol>
        {detail.map((item) => (
          <ConditionalRender
            condition={!!item.id}
            fallback={
              <Text key={JSON.stringify(item)} noPadding>
                {item.value}
              </Text>
            }
            key={JSON.stringify(item)}
          >
            <a href={`https://indicator.area1security.com/detail/${item.id}`}>{item.name}</a>
          </ConditionalRender>
        ))}
      </FlexCol>
    </ConditionalRender>
  );
};

const renderSpoofs = (key: HighlightKey, detail: Spoof[]) => (
  <ConditionalRender condition={!!detail.length} fallback={<Text>None</Text>}>
    <FlexCol>
      <FlexRow noPadding>
        <Text bold>SHOWN</Text>
        <Text bold>ACTUAL</Text>
      </FlexRow>
      {detail.map((item: Spoof, index: number) => (
        <FlexRow key={JSON.stringify(item) + index} noPadding>
          {Object.keys(infoTranslations[key] || []).map((e, index) => (
            <Text key={e + index}>{item[e as keyof Spoof]}</Text>
          ))}
        </FlexRow>
      ))}
    </FlexCol>
  </ConditionalRender>
);
