import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Form = styled.form.attrs({ 'data-testid': 'atom-form' })`
  background-color: white;
  margin: 0;
  padding: 0;

  ${({ stacked }) =>
    stacked &&
    css`
      .form-input-group {
        display: grid;
        grid-template-columns: 1fr;
      }

      label {
        padding-bottom: ${rem(8)};
      }

      button[type='submit'] {
        grid-column-start: 1;
        justify-self: center;
        margin-top: ${rem(16)};
      }
    `};
`;

Form.displayName = 'Form';

export default Form;
