import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledDiv = styled.div`
  padding: ${({ padding, theme }) => (padding ? rem(theme.spacing[padding]) : 0)};

  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `};

  ${({ pullTop, theme }) =>
    pullTop &&
    css`
      margin-top: -${rem(theme.spacing[pullTop])};
    `};

  ${({ pullRight, theme }) =>
    pullRight &&
    css`
      margin-right: -${rem(theme.spacing[pullRight])};
    `};

  ${({ pullBottom, theme }) =>
    pullBottom &&
    css`
      margin-bottom: -${rem(theme.spacing[pullBottom])};
    `};

  ${({ pullLeft, theme }) =>
    pullLeft &&
    css`
      margin-left: -${rem(theme.spacing[pullLeft])};
    `};

  ${({ pushTop, theme }) =>
    pushTop &&
    css`
      padding-top: ${rem(theme.spacing[pushTop])};
    `};

  ${({ pushRight, theme }) =>
    pushRight &&
    css`
      padding-right: ${rem(theme.spacing[pushRight])};
    `};

  ${({ pushBottom, theme }) =>
    pushBottom &&
    css`
      padding-bottom: ${rem(theme.spacing[pushBottom])};
    `};

  ${({ pushLeft, theme }) =>
    pushLeft &&
    css`
      padding-left: ${rem(theme.spacing[pushLeft])};
    `};
`;

export default function PaddedContent({ dataTestId = 'atom-padded-content', display, ...rest }) {
  return <StyledDiv data-testid={dataTestId} display={display} {...rest} />;
}
