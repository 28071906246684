import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { DEFAULT_PERMISSIONS, SHERLOCK_PERMISSIONS_COOKIE, usePermissions } from './hooks/usePermissions';

import Button from './ui/Button';
import SettingsLink from './ui/SettingsLink';

const FlexNav = styled.nav`
  align-items: center;
  background-color: black;
  display: flex;
  height: 80px;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 60px;
  margin: 0px 10px;
  width: 60px;
`;

const LogoutButton = styled(Button)`
  background: none;
  border: none;
  color: white;
  font-size: 12px;
`;

const LogoText = styled.p`
  align-items: center;
  color: white;
  display: flex;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  font-size: 25px;
  height: 80px;
  justify-content: flex-start;
  margin-right: 75px;
`;

const NavLink = styled(Link)`
  color: white;
  font-size: 20px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &[aria-current='page'] {
    border-bottom: 4px solid white;
  }
`;

const NavLeft = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: flex-start;
`;

const NavLinkDiv = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-right: 30px;
`;

const Navigation: React.FC = (): React.ReactElement => {
  const history = useHistory();
  const {
    permissions: { configPermitted, detectionViewPermitted, traceViewPermitted },
    setPermissions,
  } = usePermissions();

  const handleLogout = async () => {
    try {
      await axios.get('/logout');
    } catch (error) {
      // do nothing. just execute next lines if error
    }
    setPermissions(DEFAULT_PERMISSIONS);
    Cookies.remove(SHERLOCK_PERMISSIONS_COOKIE);
    history.push('/');
  };

  return (
    <FlexNav>
      <NavLeft>
        <Logo src="solid.svg" alt="Sherlock" />
        <LogoText>SHERLOCK</LogoText>

        {detectionViewPermitted && (
          <NavLinkDiv>
            <NavLink exact to="/">
              Detections
            </NavLink>
          </NavLinkDiv>
        )}

        {traceViewPermitted && (
          <NavLinkDiv>
            <NavLink to="/trace">Trace All</NavLink>
          </NavLinkDiv>
        )}
      </NavLeft>

      <NavLinkDiv>
        {configPermitted && <SettingsLink>Settings</SettingsLink>}
        <LogoutButton onClick={handleLogout}>Re-authenticate</LogoutButton>
      </NavLinkDiv>
    </FlexNav>
  );
};

export default Navigation;
