// $FlowFixMe
import React, { useEffect, useRef } from 'react';
import shortid from 'shortid';

import Container from './Container';
import Input from './Input';
import Label from './Label';

const Checkbox = ({
  block = false,
  checked,
  dataTestId = 'atom-checkbox',
  disabled = false,
  id = shortid.generate(),
  info,
  label,
  ...props
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.indeterminate = checked === 'indeterminate';
  }, [checked]);

  return (
    <Container block={block} data-testid={dataTestId}>
      <Input checked={!!checked} disabled={disabled} id={id} ref={ref} {...props} />
      <Label disabled={disabled} htmlFor={id}>
        {label}
      </Label>
      {info && info}
    </Container>
  );
};

export default Checkbox;
