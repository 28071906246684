import axios from 'axios';
import { get } from 'lodash';
import hash from 'hash-sum';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { rem, rgba } from 'polished';
import styled from 'styled-components';

import Alert from '../ui/Alert';
import Button from '../ui/Button';
import ConditionalRender from '../ui/ConditionalRender';
import Loading from '../ui/Loading';
import NoData from '../ui/NoData';

import PreviewOrRetractionErrorMessage from './PreviewOrRetractionErrorMessage';

const EmailView = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => rgba(p.theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(4)};
  font-family: ${(p) => p.theme.fonts.roboto};
  height: calc(100% - ${rem(132)});
  max-width: 100%;
  padding: ${(p) => rem(p.theme.spacing.md)};

  & + & {
    margin-top: ${(p) => rem(p.theme.spacing.md)};
  }

  hr {
    border: 0;
    border-top: ${(p) => p.theme.colors.tuscanBrown} solid 1px;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`;

const PreviewArea = styled.div`
  background-color: ${(p) => p.theme.colors.antiFlashWhite};
  border: ${(p) => p.theme.colors.tuscanBrown} solid 1px;
  border-radius: ${rem(4)};
  margin-top: ${(p) => rem(p.theme.spacing.lg)};
  max-height: calc(100vh - ${rem(196)});
  overflow: auto;
  padding: ${(p) => rem(p.theme.spacing.md)};
`;

/*
  ------------------   Previews Component   ---------------------------------------------------
*/

export default function Previews({ clientId, handleRetract, messages, selectedRows, setSelectedRows }) {
  const [alertTitle, setAlertTitle] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [previewErrors, setPreviewErrors] = useState([]);

  let requests = [];
  const reqMessages = [...selectedRows].map((key) => messages.find((message) => message.key === key));
  reqMessages.forEach((req) => {
    // one request for every valid message recipient
    req.clientRecipients.forEach((recipient) => {
      requests = [
        ...requests,
        {
          message_id: req.messageId,
          recipient,
        },
      ];
    });
  });

  const hashedRequests = hash(requests);

  // validPreviewIds is the array of messageIds that can be retracted based on the previews response
  const validPreviewIds = previews.reduce(
    (acc, preview) => (!preview.preview.error ? [...acc, preview.messageId] : acc),
    []
  );

  const validRetractionRecipients = previews.reduce(
    (acc, preview) => (!preview.preview.error ? [...acc, ...preview.recipients] : acc),
    []
  );

  // retractKeys are the message keys of messages with a valid requested preview and therefore retractable
  const retractKeys = reqMessages.reduce(
    (acc, req) => (validPreviewIds.includes(req.messageId) ? [...acc, req.key] : acc),
    []
  );

  const handleRetractClick = () => {
    setSelectedRows(new Set(retractKeys));
    handleRetract(validRetractionRecipients);
  };

  const handlePreviewsAndErrors = (previewData) => {
    const errorMessages = previewData.reduce(
      (acc, preview) =>
        preview.preview.error ? [...acc, { status: preview.preview.error, messageId: preview.messageId }] : acc,
      []
    );
    if (errorMessages.length) {
      setAlertTitle('Previews are not available for some recipients');
      setPreviewErrors(errorMessages);
    }
    if (previewData.length !== errorMessages.length) setPreviews(previewData);
  };

  useEffect(() => {
    const fetchPreviews = async () => {
      if (!selectedRows.size) return;
      const payload = {
        client_id: clientId,
        requests,
      };

      setLoading(true);
      setError('');
      setPreviewErrors([]);

      try {
        const { data } = await axios.post('/preview', payload);
        const previewData = get(data, 'previews', []);
        handlePreviewsAndErrors(previewData);
      } catch (error) {
        const { response = {} } = error;
        if (response?.data?.previews) {
          handlePreviewsAndErrors(response.data.previews);
        } else if (response?.statusText) setError(response.statusText);
        else setError('Request failed with an Unknown Error');
      }
      setLoading(false);
    };

    if (selectedRows.size) fetchPreviews();
    // Exhaustive deps wants 'clientId', 'requests' and 'selectedRows.size'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, hashedRequests, selectedRows.size]);

  return (
    <Tabs>
      <TabList>
        <Tab>Preview</Tab>
      </TabList>

      <TabPanel>
        <>
          <ConditionalRender condition={loading || ((!previews.length || error) && !loading)}>
            {loading ? <Loading /> : <NoData>{error ? error : 'Preview data is not available'}</NoData>}
          </ConditionalRender>

          <Alert
            expanded
            onDismiss={() => setPreviewErrors([])}
            open={!!previewErrors.length}
            title={alertTitle}
            zebraStripe
          >
            {previewErrors.map((err, i) => (
              <PreviewOrRetractionErrorMessage key={err + i} error={err} />
            ))}
          </Alert>

          <ConditionalRender condition={!!previews.length && !loading}>
            <Button icon="reload" onClick={handleRetractClick}>
              Retract ({validPreviewIds.length})
            </Button>
            <PreviewArea>
              {previews.map(({ preview }, i) => (
                <ConditionalRender condition={!!preview.html} key={preview.html + i}>
                  <EmailView
                    dangerouslySetInnerHTML={{
                      __html: preview.html,
                    }}
                    data-testid="preview-section"
                  />
                </ConditionalRender>
              ))}
            </PreviewArea>
          </ConditionalRender>
        </>
      </TabPanel>
    </Tabs>
  );
}
