import { rem } from 'polished';
import styled, { css } from 'styled-components';

const ErrorMessage = styled.p`
  color: red;
  font-family: sans-serif;
  font-size: ${rem(20)};
  font-weight: bolder;

  ${({ small }) =>
    small &&
    css`
      font-size: ${rem(15)};
      padding: ${rem(5)};
    `};
`;

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
