import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  margin-right: ${({ noMargin, theme }) => !noMargin && rem(theme.spacing.md)};
  position: relative;
  vertical-align: middle;

  svg {
    pointer-events: none;
  }
`;

export default Container;
