export type CustomerIdentity = {
  client_id?: string | null;
  client_name?: string | null;
  clientId?: string | null;
  clientName?: string | null;
};

export type Customer = {
  isDeleted: boolean;
  label: string;
  value: CustomerIdentity;
};

export type Disposition = '' | 'MALICIOUS' | 'MALICIOUS-BEC' | 'SUSPICIOUS' | 'SPOOF' | 'SPAM' | 'BULK';

export type DispositionWithLabel = {
  label: string;
  value: Disposition;
};

export type MissedVendorLabel = 'No Selection' | 'All' | 'Microsoft Miss' | 'Proofpoint Miss';
export type MissedVendorValue = '' | 'vendor_miss' | 'microsoft_miss' | 'proofpoint_miss';

export type MissedVendorWithLabel = {
  label: MissedVendorLabel;
  value: MissedVendorValue;
};

export type Attachment = {
  contentType: string;
  detection: unknown | null;
  encrypted: unknown | null;
  name: string;
  size: number;
};

export type Brand = string;

export type Envelope = string | null; //unknown;

export type Link = {
  href: string;
  text: string | null;
};

export type MaliciousFinding = string;

export type NewDomainReference = {
  [key: string]: string;
};

export type OcrContent = string | null;

export type SenderInfo = {
  asName: null | unknown;
  asNumber: null | unknown;
  geo: null | unknown;
  ip: null | unknown;
  pld: string;
};

export type Sentiment = string;

export type Spoof = {
  [key: string]: string;
};

export type ThreatCategory = {
  id?: string;
  name: string;
  value: string;
};

export type Validation = {
  comment: null | string;
  dkim: 'none' | string;
  dmarc: 'none' | string;
  spf: 'none' | string;
};

export type Header = {
  name: string;
  value: string;
};

export type HighlightParams = {
  client_id: SearchResult['clientUuid'];
  id: SearchResult['postfixIdent'];
  renderImages: boolean;
  retroscan?: boolean;
  waitSeconds: number;
};

export type HighlightResult = {
  action: 'JOURNALED' | string;
  attachments: Attachment[];
  bodyImage: string | null;
  brands: Brand[];
  clientName: string;
  envelopeFrom: Envelope[] | null;
  envelopeTo: Envelope[] | null;
  errors: string[];
  finalDisposition: Disposition;
  headers: Header[] | null;
  html: string | null | unknown;
  links: Link[];
  maliciousFindings: MaliciousFinding[];
  newDomainReferences: NewDomainReference[];
  ocrContent: OcrContent;
  raw: string;
  senderInfo: SenderInfo;
  sentiments: Sentiment[];
  spoofs: Spoof[];
  text: null | string;
  threatCategories: ThreatCategory[];
  validation: Validation;
};

export type HighlightKey = keyof HighlightResult;

export const MissedTranslations = {
  google_miss: 'Google Miss',
  microsoft_miss: 'Microsoft Miss',
  proofpoint_miss: 'Proofpoint Miss',
};

export type PrioritizedDetection = keyof typeof MissedTranslations;

export type Finding = { name: string };

export type SearchResult = {
  bccRecipient: string[] | null;
  cc: string[] | null;
  clientName: string | null;
  clientUuid: string | null;
  detectionReasons: [];
  envelopeTo: [];
  finalDisposition: string;
  findings: Finding[];
  from: string;
  isQuarantined: boolean;
  metadata: string;
  postfixIdent: string;
  prioritizedDetection: PrioritizedDetection | null;
  rownum?: number;
  storedAt: string;
  subject: string;
  to: string | string[];
  ts: string | null;
  vendorMisses: PrioritizedDetection[];
};

export type Status = { statusCode: number | null; text: string } | null;
