import axios from 'axios';
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';

const MIN_FETCH_LENGTH = 2;

import { FormParamsGetter, FormSubmitHandler, QueryValues } from './Form';

interface SearchBarProps {
  getParams: FormParamsGetter;
  handleFormSubmit: FormSubmitHandler;
  querySearchValue: string;
  searchLoading: boolean;
  setServerError: React.Dispatch<string>;
  setSearchBarValue: React.Dispatch<string>;
  setSuggestionsLoading: React.Dispatch<boolean>;
  values: QueryValues;
}

const SearchBar: React.FC<SearchBarProps> = ({
  getParams,
  handleFormSubmit,
  querySearchValue,
  searchLoading,
  setSearchBarValue,
  setServerError,
  setSuggestionsLoading,
  values,
}): React.ReactElement => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState(querySearchValue);

  const fetchSuggestions = async (searchBarValue: string) => {
    if (!values.endpoint || !values.endpoint.suggest) {
      setSuggestions([]);
      return;
    }

    const query = getParams(values.endpoint.suggest.toString(), values, searchBarValue);
    setSuggestionsLoading(true);

    try {
      const { data } = await axios.get(query);
      setSuggestions(data?.matches || []);
    } catch (e) {
      setServerError('Error fetching suggestions');
    }

    setSuggestionsLoading(false);
  };

  const onChange = (event: React.FormEvent, { newValue }: { newValue: string }) => {
    setValue(newValue.trimStart());
    setSearchBarValue(newValue.trimStart());
  };

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      const { endpoint, ...rest } = values;
      event.stopPropagation();
      handleFormSubmit(endpoint?.value || '', rest, value);
    }
  };

  const onSuggestionsFetchRequested = ({ value: newVal }: { value: string }) => {
    // interesting to note:  newVal has the current change indicating it fires before updating state
    if (newVal.trim().length > MIN_FETCH_LENGTH && !searchLoading) fetchSuggestions(newVal);
  };

  const onSuggestionSelected = (event: React.FormEvent, { suggestion }: { suggestion: string }): void => {
    const { endpoint, ...rest } = values;
    handleFormSubmit(endpoint?.value || '', rest, suggestion);
  };

  const placeholder =
    'Type prefixes of a signature name, email address, etc. Add more separated by spaces; <Enter> to submit';

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    // turns out chrome ignores off, but any other non on/off string turns it off
    // works in chrome, brave, and firefox
    // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
    autoComplete: 'stopit',
    placeholder,
    onChange,
    onKeyPress,
    value,
  };

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </div>
  );
};

export default SearchBar;

/*
        Private Functions
*/

const getSuggestionValue = (suggestion: string) => suggestion;

const renderSuggestion = (suggestion: string) => {
  return <div>{suggestion}</div>;
};
