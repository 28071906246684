import { rem } from 'polished';
import styled from 'styled-components';

const Label = styled.label.attrs({ 'data-testid': 'atom-label' })`
  align-self: center;
  color: #4c516d;
  font-family: 'roboto mono', monospace;
  font-size: ${rem(12)};
  font-weight: 500;
  letter-spacing: ${rem(0.43)};
  opacity: 0.75;
  padding-left: ${rem(4)};
  text-transform: uppercase;
`;

Label.displayName = 'Label';

export default Label;
