import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => rem(theme.spacing.lg)};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ pullBottom, theme }) =>
    pullBottom &&
    css`
      margin-bottom: ${rem(-theme.spacing.md)};
    `};
`;

export default Container;
