/**
 * TooltippedStatusIcon Component
 *
 * Renders a hoverable status icon with tooltip contents
 */

import React, { useState } from 'react';

import StatusIcon from '../../atoms/StatusIcon';
import Tooltip from '../../atoms/Tooltip';

import Container from './Container';

export default function TooltippedStatusIcon({
  dataTestId = 'molecule-tooltipped-status-icon',
  name,
  tooltipContents,
  tooltipPosition = 'bottom',
  wide = false,
  ...rest
}) {
  const [anchor, setAnchor] = useState(null);
  const [tooltipShown, setTooltipShown] = useState(false);

  // eslint-disable-next-line no-undef
  let timeoutId;

  const hideTooltip = () => {
    timeoutId = setTimeout(() => setTooltipShown(false), 1000);
  };

  const showTooltip = () => {
    clearTimeout(timeoutId);
    setTooltipShown(true);
  };

  return (
    <Container data-testid={dataTestId}>
      <StatusIcon
        onBlur={hideTooltip}
        onFocus={showTooltip}
        onMouseOut={hideTooltip}
        onMouseOver={showTooltip}
        ref={(ref) => setAnchor(ref)}
        name={name}
        {...rest}
      />

      {tooltipContents && (
        <Tooltip anchor={anchor} position={tooltipPosition} visible={tooltipShown} wide={wide}>
          {tooltipContents}
        </Tooltip>
      )}
    </Container>
  );
}
