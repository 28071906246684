import { rem } from 'polished';
import styled, { css } from 'styled-components';

const MaxHeight = styled.div<{ height: number; scrollable?: boolean }>`
  background-color: transparent;
  max-height: ${({ height }) => rem(height)};
  scroll-behavior: smooth;
  scrollbar-color: ${({ theme }) => theme.colors.antiFlashWhite} transparent;
  scrollbar-width: thin;

  ${({ scrollable = true }) => css`
    overflow-y: ${scrollable ? 'auto' : 'hidden'};
  `}
`;

MaxHeight.displayName = 'MaxHeight';

export default MaxHeight;
