import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

import Cell from './Cell';
import Header from './Header';
import Row from './Row';

const Container = styled.table<{
  dimmed?: boolean;
  fixed?: boolean;
  spaced?: boolean;
  verticalAlign?: string;
  zebraStripes?: boolean;
}>`
  border: 0;
  border-collapse: collapse;
  width: 100%;

  &:last-child:after {
    content: ' ';
    display: block;
    height: ${({ theme }) => rem(theme.spacing.xs)};
  }

  ${({ fixed }) =>
    fixed &&
    css`
      table-layout: fixed;
    `};

  ${({ dimmed, theme }) =>
    dimmed &&
    css`
      color: ${theme.colors.lightSlateGray};
    `};

  ${({ spaced, theme }) =>
    spaced &&
    css`
      margin-bottom: ${rem(theme.spacing.lg)};

      /* stylelint-disable-next-line no-duplicate-selectors */
      ${Cell} {
        padding: ${rem(theme.spacing.md)} 0;
      }

      /* stylelint-disable-next-line selector-type-no-unknown */
      ${Cell}:first-child, ${Header}:first-child {
        padding-left: ${rem(theme.spacing.md)};
      }

      /* stylelint-disable-next-line selector-type-no-unknown */
      ${Cell}:last-child, ${Header}:last-child {
        padding-right: ${rem(theme.spacing.md)} !important;
      }
    `};

  /* stylelint-disable-next-line no-duplicate-selectors */
  ${Cell} {
    vertical-align: ${({ verticalAlign }) => verticalAlign};
  }

  ${({ theme, zebraStripes }) =>
    zebraStripes &&
    css`
      tbody {
        ${Row}:nth-child(odd) {
          background-color: ${theme.colors.ghostWhite};
        }
      }
    `};
`;

export default Container;
