import { rem } from 'polished';
import styled from 'styled-components';

const Text = styled.span`
  color: ${({ theme }) => theme.colors.pinkOrange};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 500;
  font-size: ${rem(14)};
  text-transform: uppercase;
`;

export default Text;
