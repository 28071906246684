import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Button = styled.button`
  align-self: center;
  background-color: #0089cc;
  border: solid ${rem(1)} #f2f2f2;
  color: #fff;
  cursor: pointer;
  font-family: sans-serif;
  font-size: ${rem(14)};
  font-weight: bolder;
  padding: ${rem(5)} ${rem(10)};

  ${({ small }) =>
    small &&
    css`
      font-size: ${rem(12)};
      font-weight: normal;
      padding: ${rem(5)};
    `};

  ${({ alignStart }) =>
    alignStart &&
    css`
      align-self: flex-start;
    `};
`;

Button.displayName = 'Button';

export default Button;
