import React from 'react';

import Button from '../ui/Button';

type Props = {
  onClose: React.MouseEventHandler;
};

export default function Closer({ onClose }: Props): React.ReactElement {
  return (
    <Button className="closer" onClick={onClose}>
      X
    </Button>
  );
}
