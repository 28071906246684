import Cookies from 'js-cookie';
import hash from 'hash-sum';

import qs from 'qs';
import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme from './Config/theme';
import FindingsConfig from './FindingsConfig';
import Navigation from './Navigation';
import SearchApp from './SearchApp';
import TraceApp from './TraceApp';

import { CustomersProvider } from './hooks/useCustomers';
import {
  PermissionsProvider,
  PermissionsType,
  SHERLOCK_PERMISSIONS_COOKIE,
  usePermissions,
} from './hooks/usePermissions';

import Center from './ui/Center';
function NoAuth() {
  return <Center>You are not authorized to use Mailsearch. Please talk to your manager if this is an error.</Center>;
}

const convertToBoolean = (permissionsResponse: qs.ParsedQs) =>
  Object.keys(permissionsResponse).reduce(
    (permissions, key) => ({ ...permissions, [key]: permissionsResponse[key] === 'true' }),
    {}
  ) as PermissionsType;

const Main: React.FC = (): React.ReactElement => {
  const { permissions, setPermissions } = usePermissions();
  const location = useLocation();
  const history = useHistory();
  const { configPermitted, detectionViewPermitted, traceViewPermitted } = permissions;

  const permitResponse = qs.parse(location.search, { ignoreQueryPrefix: true });
  const hashedPermitResponse = hash(permitResponse);

  useEffect(() => {
    if (permitResponse && permitResponse.configPermitted) {
      const converted = convertToBoolean(permitResponse);
      // expires in 2.4 hours
      Cookies.set(SHERLOCK_PERMISSIONS_COOKIE, converted, { expires: 0.1 });

      setPermissions(converted);

      history.push('/');
    }
    // the hash prevents an infinite loop
    // req'd are 'history', 'permitResponse', and 'setPermissions'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashedPermitResponse, setPermissions]);

  return (
    <Switch>
      <Route path="/no-auth" component={NoAuth} />
      {detectionViewPermitted && <Route exact path="/" component={SearchApp} />}
      {configPermitted && <Route exact path="/settings" component={FindingsConfig} />}
      {traceViewPermitted && <Route exact path="/trace" component={TraceApp} />}
      <Route component={NoAuth} path="*" />
    </Switch>
  );
};

const App: React.FC = (): React.ReactElement => {
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <PermissionsProvider>
          <Navigation />
          <CustomersProvider>
            <Main />
          </CustomersProvider>
        </PermissionsProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
