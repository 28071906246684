import React from 'react';

import PaddedContent from '../ui/atoms/PaddedContent';

export default function PreviewOrRetractionErrorMessage({ error }) {
  return (
    <PaddedContent style={{ textAlign: 'left' }}>
      <dl>
        <dt>
          <b>Message ID: </b>
        </dt>
        <dd>{error.messageId}</dd>
        <dt>
          <b>Reason: </b>
        </dt>
        <dd>{error.status}</dd>
      </dl>
    </PaddedContent>
  );
}
