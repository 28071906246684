import styled from 'styled-components';

const Instructions = styled.p`
  font-family: sans-serif;
  font-size: 16px;
  font-style: italic;
  margin: 5px 50px 0px 50px;
`;

Instructions.displayName = 'Instructions';

export default Instructions;
