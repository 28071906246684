import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as SmallAnimation } from './loading-alt.svg';
import { ReactComponent as Animation } from './loading.svg';

const Container = styled.div<{ dark?: boolean; small?: boolean; dataTestId?: string }>`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-height: ${rem(64 * 2)};

  ${({ small }) =>
    small &&
    css`
      align-items: left;
      display: inline-flex;
      min-height: 0;
      justify-content: left;
    `};

  svg {
    fill: #00bfff;
    stroke: #00bfff;

    ${({ dark }) =>
      dark &&
      css`
        fill: #4c516d;
        stroke: #4c516d;
      `};
  }
`;
interface Props {
  dark?: boolean;
  dataTestId?: string;
  small?: boolean;
}
const Loading: React.FC<Props> = ({ dark, dataTestId = 'atom-loading', small }) => {
  return (
    <Container dark={dark} data-testid={dataTestId} small={small}>
      {small ? <SmallAnimation /> : <Animation />}
    </Container>
  );
};
export default Loading;
