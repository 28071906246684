import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.div.attrs({ role: 'tooltip' })`
  background-color: ${({ theme }) => theme.colors.ghostWhite};
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => rgba(theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(16)};
  color: ${({ theme }) => theme.colors.lightSlateGray} !important;
  display: inline-block;
  font-size: ${rem(12)};
  font-weight: auto;
  line-height: 150% !important;
  min-width: ${rem(250)};
  max-width: fit-content;
  overflow-wrap: break-word;
  padding: ${rem(4)} ${rem(8)};
  position: absolute;
  pointer-events: none;
  text-align: left;
  text-transform: none;
  transition: opacity 150ms ${({ theme }) => theme.timing.easeOutCirc},
    transform 150ms ${({ theme }) => theme.timing.easeOutBack};
  z-index: -999;

  ${({ left, top }) => css`
    transform: translate(${left || 0}px, ${top || 0}px);
  `};

  ${({ wide }) =>
    wide &&
    css`
      min-width: ${rem(400)};
    `};

  ${({ visible }) =>
    visible &&
    css`
      pointer-events: auto;
      z-index: 999;
    `};

  &:after {
    border: ${rem(7)} solid transparent;
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
  }

  &[aria-hidden='true'] {
    opacity: 0;

    ${({ left, position, top }) => {
      if (position === 'bottom')
        return css`
          transform: translate3d(${rem(left || 0)}, ${rem((top || 0) + 10)}, 0);
        `;

      if (position === 'left')
        return css`
          transform: translate3d(${rem((left || 0) - 10)}, ${rem(top)}, 0);
        `;

      if (position === 'right')
        return css`
          transform: translate3d(${rem((left || 0) + 10)}, ${rem(top)}, 0);
        `;

      if (position === 'top')
        return css`
          transform: translate3d(${rem(left || 0)}, ${rem((top || 0) - 10)}, 0);
        `;

      return null;
    }};
  }

  ${({ arrowAlign, position }) => {
    if (position === 'bottom')
      return css`
        &:after {
          border-bottom-color: ${({ theme }) => theme.colors.ghostWhite};
          bottom: 100%;
          left: 50%;
          margin-left: ${rem(-7)};

          ${arrowAlign === 'end' &&
          css`
            left: 100%;
            margin-left: ${rem(-24)};
          `};
        }
      `;

    if (position === 'left')
      return css`
        &:after {
          left: 100%;
          border-left-color: ${({ theme }) => theme.colors.ghostWhite};
          margin-top: ${rem(-7)};
          top: 50%;
        }
      `;

    if (position === 'right')
      return css`
        &:after {
          border-right-color: ${({ theme }) => theme.colors.ghostWhite};
          margin-top: ${rem(-7)};
          right: 100%;
          top: 50%;
        }
      `;

    if (position === 'top')
      return css`
        &:after {
          border-top-color: ${({ theme }) => theme.colors.ghostWhite};
          left: 50%;
          margin-left: ${rem(-7)};
          top: 100%;
        }
      `;

    return null;
  }};
`;

export default Container;
