// @ts-nocheck
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useFormik } from 'formik';
import hash from 'hash-sum';
import { get } from 'lodash';
import { rem } from 'polished';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useCustomers } from '../hooks/useCustomers';
import { usePermissions } from '../hooks/usePermissions';

import { DEFAULT_DAYS_BACK, DEFAULT_RESULTS_LIMIT, DEFAULT_WAIT_SECS, DISPOSITIONS } from '../utils';

import Button from '../ui/Button';
import Center from '../ui/Center';
import ConditionalRender from '../ui/ConditionalRender';
import DateTimeSelector from '../ui/DateTimeSelector';
import Dropdown from '../ui/Dropdown';
import ErrorMessage from '../ui/ErrorMessage';
import Inline from '../ui/Inline';
import Instructions from '../ui/Instructions';
import Label from '../ui/Label';
import Loading from '../ui/Loading';

import {
  CustomerIdentity,
  Customer,
  Disposition,
  DispositionWithLabel,
  HighlightParams,
  MissedVendorWithLabel,
  SearchResult,
} from '../types';

import SearchBar from './SearchBar';

/*
    Styled Components --------------------------------------------------------------
*/

const MarginedContainer = styled.div`
  display: flex;

  justify-content: flex-start;
  margin-top: ${({ theme }) => rem(theme.spacing.sm)};
`;

const InlineContainer = styled.div<{ small?: boolean; spaceBetween?: boolean }>`
  display: flex;
  justify-content: flex-start;
  margin: ${rem(5)} ${rem(50)};
  width: 95%;

  ${({ noLeftMargin }) =>
    noLeftMargin &&
    css`
      margin: ${rem(5)} 0;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 75%;
    `};

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `};
`;

const Input = styled.input<{ large?: boolean }>`
  font-family: sans-serif;
  font-size: ${rem(15)};
  max-height: ${rem(38)};
  text-indent: ${rem(10)};
  width: 50px;
  ${({ large }) =>
    large &&
    css`
      width: 80%;
    `};
`;

/*
    Constants --------------------------------------------------------------
*/

const HEADER_CUSTOMER_ID = 'X-A1S-CUSTOMER-ID';
const HEADER_INTERNAL_SUPPORT = 'X-A1S-INTERNAL-SUPPORT';
const INTERNAL_REQUEST_HEADER = 'INTERNAL_REQUEST_HEADER';
const MISSED_VENDORS: MissedVendorWithLabel[] = [
  { label: 'No Selection', value: '' },
  { label: 'Any', value: 'vendor_miss' },
  { label: 'Google Miss', value: 'google_miss' },
  { label: 'Microsoft Miss', value: 'microsoft_miss' },
  { label: 'Proofpoint Miss', value: 'proofpoint_miss' },
];

const SEARCH_ENDPOINTS: SearchEndpoint[] = [
  {
    label: 'Default',
    value: '/unisearch/search',
    suggest: '/auto',
    highlightParams: {},
    excludeParams: [],
  },
  {
    label: 'Retro Scan',
    value: '/unisearch/retroscan',
    suggest: false,
    highlightParams: {
      retroscan: true,
    },
    excludeParams: [],
  },
];

/*
    Types --------------------------------------------------------------
*/

type SearchEndpoint = {
  excludeParams: Array<keyof HighlightParams>;
  highlightParams: Partial<HighlightParams>;
  label: string;
  suggest: boolean | 'string';
  value: string;
};

export type FormSubmitHandler = (
  endpoint: string,
  values: QueryValues,
  selectedSuggestion: string,
  urlQuery?: string | null
) => void;

export type FormParamsGetter = (endpoint: string, values: QueryValues, searchBarValue: string) => string;
export interface QueryValues {
  dateRange?: Date[];
  disposition?: DispositionWithLabel;
  endpoint?: SearchEndpoint;
  fullSubjectSearch?: string;
  limit?: number;
  selectedCustomer?: Customer;
  vendorMiss?: MissedVendorWithLabel;
  waitSeconds?: number;
}

type formValuesChangeCallback = (values: QueryValues) => void;
type setSearchResultCallback = (rows: SearchResult[], status: { statusCode: number | null; text: string }) => void;
type setWaitSecondsCallback = (seconds: number) => void;

/*
    Form Component --------------------------------------------------------------
*/
interface Props {
  onValuesChanged?: formValuesChangeCallback;
  setSearchResult: setSearchResultCallback;
  setWaitSeconds: setWaitSecondsCallback;
}

export default function Form({ onValuesChanged, setSearchResult, setWaitSeconds }: Props): JSX.Element {
  const { customers, error: customersError, loading: customersLoading } = useCustomers();
  const {
    permissions: { searchAllPermitted },
  } = usePermissions();

  const area1 = customers.find((c) => c.label === 'Area 1');
  const { clientId, clientName }: CustomerIdentity = get(area1, 'value', { clientId: null, clientName: null });
  const allCustomers: Customer = {
    isDeleted: false,
    value: { clientId, clientName },
    label: 'ALL CUSTOMERS',
  };

  const searchAppCustomers: Customer[] = searchAllPermitted ? [allCustomers, ...customers] : customers;
  const hashedSearchAppCustomers = hash(searchAppCustomers);

  const location = useLocation();

  const [expandForm, setExpandForm] = useState(false);
  const [hasUserSearched, setHasUserSearched] = useState(false);
  const [querySearchValue, setQuerySearchValue] = useState('');
  const [searchBarValue, setSearchBarValue] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [serverError, setServerError] = useState('');
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);

  let selectedCustomer: Customer = {} as Customer;
  let values: QueryValues = {};

  const handleFormSubmit = async (
    endpoint: string,
    values: QueryValues,
    selectedSuggestion = '',
    urlQuery: string | null = null
  ) => {
    setServerError('');
    setSearchLoading(true);

    const query = urlQuery ? `${endpoint}${urlQuery}` : getParams(endpoint, values, selectedSuggestion);

    const {
      value: { clientId },
    } = selectedCustomer;

    try {
      const response = await axios.get(query, {
        headers: {
          [HEADER_CUSTOMER_ID]: clientId,
          [HEADER_INTERNAL_SUPPORT]: 'true',
          [INTERNAL_REQUEST_HEADER]: 'a1s-is-sherlock',
        },
      });
      const { data = [], status = 200 } = response;
      const results = get(data, 'messages', []);
      const text = get(data, 'status', '');
      setSearchResults(results, query, { statusCode: status, text }, setSearchResult);
      setHasUserSearched(true);
    } catch (e) {
      const { message = '', response = {} } = e;
      const statusText = get(response, 'statusText', '');

      setServerError(getErrorMessage(message, statusText, query));
    }

    setSearchLoading(false);
  };

  const handleKeyPress = (event: KeyboardEvent): void => {
    if (event.key === 'Enter' && !location.search && !searchLoading && !customersLoading) {
      const { endpoint, ...rest } = values;
      handleFormSubmit(endpoint.value, rest, searchBarValue);
    }
  };

  // enables search request on enter
  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  });

  const formik = useFormik({
    initialValues: {
      dateRange: [startOfDay(subDays(new Date(), DEFAULT_DAYS_BACK)), endOfDay(new Date())],
      disposition: DISPOSITIONS[0],
      endpoint: SEARCH_ENDPOINTS[0],
      fullSubjectSearch: '',
      limit: DEFAULT_RESULTS_LIMIT,
      selectedCustomer: searchAppCustomers[0],
      vendorMiss: MISSED_VENDORS[0],
      waitSeconds: DEFAULT_WAIT_SECS,
    },
    onSubmit: (values: QueryValues) => {
      if (!location.search && !searchLoading && !customersLoading) {
        const { endpoint, ...rest } = values;
        handleFormSubmit(endpoint.value, rest, searchBarValue);
      }
    },
    validate: (values) => validate(values),
  });

  const { errors, handleChange, handleSubmit, setErrors, setFieldValue } = formik;
  values = formik.values;

  useEffect(() => {
    if (hasUserSearched) {
      const { endpoint, ...rest } = formik.values;
      handleFormSubmit(endpoint.value, rest, searchBarValue);
    }
    // eslint-disable-next-line
  }, [formik.values.endpoint?.value]);

  useEffect(() => {
    if (onValuesChanged) onValuesChanged(values);
    // REFACTOR: 'values' should probably be put into a state in the future
    // eslint-disable-next-line
  }, [formik.values, onValuesChanged]);

  const { dateRange, disposition, endpoint, fullSubjectSearch, limit, vendorMiss, waitSeconds } = values;
  selectedCustomer = values.selectedCustomer as Customer;

  // set selected customer to 'ANY CUSTOMER w/ a1s client_id and client_name once customers are loaded
  useEffect(() => {
    setFieldValue('selectedCustomer', searchAppCustomers[0]);
    // searchAppCustomers is not added to the effect dependencies b/c it's an object and would run on every re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashedSearchAppCustomers]);

  const urlParams = location.search ? qs.parse(location.search, { ignoreQueryPrefix: true }) : {};
  const hashedUrlParams = hash(urlParams);

  const hashedHandleSubmitFunction = hash(handleFormSubmit);

  // handle search request if there is a url query string
  useEffect(() => {
    const paramKeys = Object.keys(urlParams);
    if (paramKeys.length) {
      const reqdFound = reqdParams.every((param) => paramKeys.includes(param));
      const allParamsAllowed = paramKeys.every((param) => allowedParams.includes(param));

      if (!reqdFound || !allParamsAllowed) {
        setServerError('Invalid url query parameters');
        return;
      }

      const query = `?q=${encodeURIComponent(urlParams['q'] as string)}${getQueryString(urlParams)}`;
      handleFormSubmit(values.endpoint.value, {}, '', query);
    }
    // wants 'urlParams' and 'handleFormSubmit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashedHandleSubmitFunction, hashedUrlParams]);

  // fill in form with query string params
  useEffect(() => {
    if (urlParams?.q) {
      setQuerySearchValue(urlParams['q'] as string);
      setFormFields(urlParams, setFieldValue, searchAppCustomers);
    }
    // wants 'searchAppCustomers', 'urlParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashedSearchAppCustomers, hashedUrlParams, setFieldValue]);

  const onFormExpandChange = (e: React.MouseEvent) => {
    e.preventDefault();
    setExpandForm(!expandForm);
  };

  const handleWaitSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const waitSeconds = parseInt(event.target.value);
    setFieldValue('waitSeconds', waitSeconds);
    setWaitSeconds(waitSeconds);
  };

  if (customersLoading)
    return (
      <Center>
        <Loading />
      </Center>
    );

  return (
    <>
      <ConditionalRender condition={!customersLoading && customers.length > 0}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <ConditionalRender condition={serverError || customersError}>
            <Center>
              <ErrorMessage>{`Error: ${serverError || customersError}`}</ErrorMessage>
            </Center>
          </ConditionalRender>

          <ConditionalRender condition={!!Object.keys(errors).length}>
            <InlineContainer>
              {Object.keys(errors).map((key) => (
                <ErrorMessage key={key}>{errors[key as keyof typeof errors]}</ErrorMessage>
              ))}
            </InlineContainer>
          </ConditionalRender>

          <InlineContainer>
            <Inline>
              <Label htmlFor="customer">Customer:</Label>
              <Dropdown
                id="customer"
                isClearable={true}
                isSearchable={true}
                name="selectedCustomer"
                onChange={(selected: React.FormEvent<HTMLSelectElement>) => {
                  setErrors({});
                  setServerError('');
                  setFieldValue('selectedCustomer', selected);
                }}
                options={searchAppCustomers}
                value={selectedCustomer}
              />
            </Inline>

            <Inline small>
              <Label htmlFor="disposition">Disposition:</Label>
              <Dropdown
                className="choiceSelect"
                id="disposition"
                isClearable={false}
                isSearchable
                name="disposition"
                onChange={(disposition: Disposition) => setFieldValue('disposition', disposition)}
                options={DISPOSITIONS}
                value={disposition}
              />
            </Inline>

            <Inline>
              <Label htmlFor="limit">Results Limit:</Label>
              <Input id="limit" name="limit" onChange={handleChange} type="text" value={limit} />
            </Inline>

            <Inline small>
              <Label htmlFor="search-endpoint">Endpoint:</Label>
              <Dropdown
                className="choiceSelect"
                id="search-endpoint"
                isClearable={false}
                name="endpoint"
                onChange={(endpoint: SearchEndpoint) => setFieldValue('endpoint', endpoint)}
                options={SEARCH_ENDPOINTS}
                value={endpoint}
              />
            </Inline>

            <Button onClick={(e: React.MouseEvent) => onFormExpandChange(e)} type="button">
              Expand Search Fields +
            </Button>
          </InlineContainer>

          <ConditionalRender condition={expandForm}>
            <>
              <Instructions>
                Click on either the start or end date. Then click on a start date and an end date in the dropdown
                calendar to choose a new period. Or simply choose from the quick days back selections.
              </Instructions>
              <InlineContainer>
                <Inline large>
                  <DateTimeSelector
                    dateRange={dateRange || []}
                    name="start"
                    onChange={(dateRange: Date[]) => {
                      setErrors({});
                      setFieldValue('dateRange', dateRange);
                    }}
                  />
                </Inline>

                <Inline small>
                  <Label htmlFor="wait-seconds">Preview Wait (secs):</Label>
                  <Input
                    id="wait-seconds"
                    name="waitSeconds"
                    onChange={(e) => handleWaitSecondsChange(e)}
                    type="text"
                    value={waitSeconds}
                  />
                </Inline>

                <Inline large>
                  <Label htmlFor="subject" lessMargin>
                    Exact Subject Match:
                  </Label>
                  <Input
                    id="subject"
                    large
                    name="fullSubjectSearch"
                    onChange={handleChange}
                    type="text"
                    value={fullSubjectSearch}
                  />
                </Inline>
              </InlineContainer>
            </>
          </ConditionalRender>

          <InlineContainer noLeftMargin>
            <SearchBar
              getParams={getParams}
              handleFormSubmit={handleFormSubmit}
              querySearchValue={querySearchValue}
              searchLoading={searchLoading}
              setSearchBarValue={setSearchBarValue}
              setServerError={setServerError}
              setSuggestionsLoading={setSuggestionsLoading}
              values={values}
            />
            <MarginedContainer>
              <Label htmlFor="missedVendor">Vendor Miss:</Label>
              <Dropdown
                id="missed_vendor"
                isClearable={false}
                isSearchable
                name="vendorMiss"
                onChange={(missedVendor: MissedVendorWithLabel) => setFieldValue('vendorMiss', missedVendor)}
                options={MISSED_VENDORS}
                value={vendorMiss}
              />
            </MarginedContainer>
          </InlineContainer>
        </form>
      </ConditionalRender>

      <ConditionalRender condition={customersLoading || searchLoading || suggestionsLoading}>
        <Loading />
      </ConditionalRender>
    </>
  );
}

/*
    Private functions
*/

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const setVendorMissFromUrl = (missType: string = '', setFieldValue: fieldValueSetter) => {
  if (missType.includes('microsoft_miss'))
    setFieldValue('vendorMiss', { label: 'Microsoft Miss', value: 'microsoft_miss' });
  else if (missType.includes('proofpoint_miss'))
    setFieldValue('vendorMiss', { label: 'Proofpoint Miss', value: 'proofpoint_miss' });
  else if (missType.includes('vendor_miss')) setFieldValue('vendorMiss', { label: 'All', value: 'vendor_miss' });
  else setFieldValue('vendorMiss', { label: 'No Selection', value: '' });
};

const getParams = (endpoint: string, values: QueryValues, searchBarValue = '') => {
  const {
    dateRange = [],
    disposition = { value: '' },
    fullSubjectSearch,
    limit,
    selectedCustomer = {
      isDeleted: false,
      label: '',
      value: {
        clientName: '',
        clientId: '',
      },
    },
    vendorMiss = { value: '' },
    waitSeconds,
  } = values;
  const [start, end] = dateRange;
  const {
    label,
    value: { clientId },
  }: Customer = selectedCustomer;

  const searchAllCustomers = label === 'ALL CUSTOMERS';

  let params = endpoint;
  params += `?q=${encodeURIComponent(searchBarValue)}`;
  // vendorMiss is part of the q param
  params += vendorMiss.value ? `%20${vendorMiss.value}` : '';
  params += '&detections_only=true';
  params += start ? `&start=${start.toISOString()}` : '';
  params += end ? `&end=${end.toISOString()}` : '';
  params += `&limit=${limit}`;
  params += `&waitSeconds=${waitSeconds}`;
  // sets internal view, for results like metadata, findings and Area1 usage rules
  params += `&searchAllCustomers=${searchAllCustomers}`;
  params += `&client_id=${clientId}`;
  // only pass final disposition if it is a specific value
  params += disposition.value ? `&final_disposition=${disposition.label}` : '';
  params += fullSubjectSearch ? `&fullSubjectSearch=${encodeURIComponent(fullSubjectSearch)}` : '';
  return params;
};

const highlight = (emailPart: string, queryParams: string[]) => {
  if (!emailPart) return emailPart;
  const emailParts = emailPart.split(/[ ]+/);
  const boldMap = emailParts.map((part) => (queryParams.includes(part) ? '<b>' + part + '</b>' : part));
  return boldMap.join(' ');
};

const setSearchResults = (
  results: SearchResult[],
  query: string,
  status: { statusCode: number; text: string },
  setSearchResult: setSearchResultCallback
) => {
  const rows: SearchResult[] = [];
  const terms = query.split(/[ ]+/);
  let count = 0;

  const camelCasedResults = camelcaseKeys(results, { deep: true });

  camelCasedResults.forEach((email) => {
    const {
      bccRecipient = [],
      cc = [],
      clientName = '',
      clientUuid = '',
      detectionReasons = [],
      envelopeTo = [],
      finalDisposition = '',
      findings = [],
      from = '',
      isQuarantined = false,
      metadata = '{}',
      postfixIdent = '',
      prioritizedDetection = '',
      storedAt = '',
      subject = '',
      to = [],
      ts = '',
      vendorMisses = [],
    } = email;

    count++;

    rows.push({
      bccRecipient,
      cc,
      clientName: highlight(clientName || 'Missing name', terms),
      clientUuid,
      detectionReasons,
      envelopeTo,
      finalDisposition,
      findings,
      from: highlight(from || 'Missing from', terms),
      isQuarantined,
      metadata,
      postfixIdent,
      prioritizedDetection,
      rownum: count,
      storedAt,
      subject: highlight(subject || '', terms),
      to,
      ts: ts ? ts.replace('T', ' ') : null,
      vendorMisses,
    });
  });

  setSearchResult(rows, status);
};

const validate = (values: QueryValues) => {
  // form validation rules: start >= end
  const [start, end] = values.dateRange || [];
  const errors: { date?: string } = {};
  if (end < start) errors.date = 'The end date cannot be before the start date';
  return errors;
};

const reqdParams = ['q', 'client_id'];

const allowedParams = [
  'client_id',
  'days_back',
  'end',
  'final_disposition',
  'fullSubjectSearch',
  'searchAllCustomers',
  'limit',
  'q',
  'searchAllCustomers',
  'start',
  'waitSeconds',
];

const getQueryString = (urlParams: qs.ParsedQs) => {
  const params = Object.keys(urlParams);
  return params.reduce((qString: string, param: string) => {
    if (param !== 'q' && allowedParams.includes(param)) {
      return `${qString}&${param}=${encodeURIComponent(urlParams[param as keyof qs.ParsedQs] as string)}`;
    } else {
      return qString;
    }
  }, '');
};

const setDisposition = (finalDisposition: Disposition) =>
  DISPOSITIONS.map((d) => d.value).includes(finalDisposition)
    ? DISPOSITIONS.find((d) => d.value === finalDisposition)
    : DISPOSITIONS[0];

type fieldValueSetter = (field: string, values: unknown) => void;

const setFormFields = (urlParams: qs.ParsedQs, setFieldValue: fieldValueSetter, searchAppCustomers: Customer[]) => {
  Object.keys(urlParams).forEach((param) => {
    switch (param) {
      case 'client_id':
        // eslint-disable-next-line no-case-declarations
        const customer = searchAppCustomers.find((c) => c.value.client_id === urlParams.client_id);
        if (customer) setFieldValue('selectedCustomer', customer);
        break;
      case 'days_back':
        setFieldValue('dateRange', [
          startOfDay(subDays(new Date(), urlParams.days_back as unknown as number)),
          endOfDay(new Date()),
        ]);
        break;
      case 'final_disposition':
        setFieldValue('disposition', setDisposition(urlParams.final_disposition as unknown as Disposition));
        break;
      case 'limit':
        setFieldValue('limit', urlParams.limit);
        break;
      case 'q':
        setVendorMissFromUrl(urlParams.q as string, setFieldValue);
        break;
      case 'start':
        if (urlParams.end) setFieldValue('dateRange', [urlParams.start, urlParams.end]);
        break;
      case 'waitSeconds':
        setFieldValue('waitSeconds', urlParams.waitSeconds);
        break;
    }
  });
};

const getErrorMessage = (message: string, statusText: string, query: string) => {
  if (message) {
    if (message === 'Network Error') {
      return 'Network Error. You likely need to re-authenticate.';
    }
    return message;
  }
  if (!statusText) {
    return `Query: '${query}' Failed with an Unknown Error`;
  }
  return statusText;
};
