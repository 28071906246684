import React from 'react';
import styled, { css } from 'styled-components';

const Time = styled.time`
  display: block;
  font: ${(p) => p.theme.colors.raisinBlack};
  word-wrap: break-word;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ smaller }) =>
    smaller &&
    css`
      font-size: 80%;
    `}
`;

export default function TimeStamp({ bold = false, date, smaller = false }) {
  return (
    <Time bold={bold} smaller={smaller}>
      {date}
    </Time>
  );
}
