const colors = {
  aliceBlue: '#edf2fb',
  antiFlashWhite: '#f2f3f5',
  aquamarine: '#7CF8AC',
  ashGrey: '#B0BEC5',
  azureishWhite: '#e1e7ee',
  black: '#000',
  blanchedAlmond: '#ffecd1',
  bleuDeFrance: '#25A0EB',
  blueGreen: '#149bb3',
  brightLilac: '#E28FF2',
  cadetGrey: '#90A4AE',
  darkSlateGray: '#2a4b72',
  deepSkyBlue: '#03bffe',
  deepSpaceSparkle: '#455a64',
  diamond: '#B3EBFE',
  dimGray: '#656A77',
  ebony: '#5d5d5d',
  gainsboro: '#d8e0ea',
  ghostWhite: '#f7fbff',
  glitter: '#E7E8EC',
  green: '#19a677',
  grayBlue: '#8F95a5',
  independence: '#4d5673',
  illuminatingEmerald: '#3B8C75',
  jet: '#333',
  lightGray: '#CCD1DC',
  lightSlateGray: '#7a8196',
  magicMint: '#9cf4dc',
  bulk: '#50A38B',
  malicious: '#2D61FF',
  maliciousFrom: '#7094FF',
  maliciousTo: '#1F57FF',
  maliciousBec: '#022EB4',
  maliciousBecFrom: '#023EF2',
  maliciousBecTo: '#0239DE',
  manatee: '#989ca6',
  mauve: '#E4AFFF',
  maximumPurple: '#843280',
  mediumAquamarine: '#64CAAD',
  mellowYellow: '#FFDC82',
  middleBlueGreen: '#74E8C9',
  mineralGreen: '#3C5C5E',
  mystic: '#e1e7ee',
  neonCarrot: '#FFA94D',
  noDisposition: '#8B8B8E',
  onyx: '#383839',
  orchid: '#D968D3',
  parisGreen: '#4EC987',
  paleTurquoise: '#b2f5e5',
  papayaWhip: '#FFF3D6',
  peach: '#FFEAB5',
  pastelBlue: '#b8c1cf',
  pastelGray: '#cbcbcb',
  pastelRed: '#ff6969',
  pinkOrange: '#ff9169',
  platinum: '#e5e8ea',
  polishedPine: '#50A38B',
  pumpkin: '#FF8C1A',
  purpureus: '#A650A1',
  raisinBlack: '#242425',
  redOrange: '#ff4f4f',
  rhythm: '#777f96',
  sonicSilver: '#797979',
  spam: '#00B3D9',
  spamFrom: '#1FD6FF',
  spamTo: '#00A7CC',
  spanishGray: '#929292',
  spoof: '#EEE3AB',
  spoofFrom: '#F5EECC',
  spoofTo: '#E3D178',
  starCommandBlue: '#1179B9',
  steelTeal: '#607D8B',
  sulu: '#B8F15F',
  sunglow: '#ffcc4d',
  suspicious: '#903CFF',
  suspiciousFrom: '#8B33FF',
  suspiciousTo: '#C599FF',
  topaz: '#FFC168',
  tuscanBrown: '#e7e8ec',
  vividSkyBlue: '#02d7ff',
  white: '#fff',
};

export default colors;
