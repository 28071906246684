import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Label = styled.label<{ lessMargin?: boolean }>`
  font-size: ${rem(14)};
  font-weight: bold;
  margin-right: 5px;
  margin-top: 10px;

  ${({ lessMargin }) =>
    lessMargin &&
    css`
      margin-top: 5px;
    `};
`;

Label.displayName = 'Label';

export default Label;
