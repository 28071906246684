import { rem, rgba } from 'polished';
import * as React from 'react';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';

import Button from '../../Button';
import MaxHeight from '../../MaxHeight';
import PaddedContent from '../PaddedContent';
import Section from '../../Section';
import Subtitle from '../../Subtitle';
import TextStyle from '../../TextStyle';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Centered = styled.div`
  position: relative;
  text-align: center;
`;

export const Container = styled(animated.div).attrs(({ open }) => ({ 'aria-hidden': !open, role: 'alert' }))`
  background-color: ${({ dark, theme }) => (dark ? theme.colors.ghostWhite : 'transparent')};
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => rgba(theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(16)};
  left: 50%;
  min-height: ${rem(150)};
  margin: ${({ theme }) => rem(theme.spacing.lg)};
  opacity: 0;
  padding: ${({ theme }) => rem(theme.spacing.lg)};
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: ${rem(320)};
  max-width: ${({ expanded }) => (expanded ? rem(820) : rem(480))};
  z-index: 999;

  ${({ expanded }) =>
    expanded &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function Alert(props) {
  const {
    dark = true,
    dataTestId = 'molecule-alert',
    expanded = false,
    message = 'dialogs:dismiss',
    open = false,
    title,
    zebraStripe = false,
  } = props;

  const transitions = useTransition(open, null, {
    config: { tension: 500, friction: 35, mass: 1 },
    from: { opacity: 0, transform: 'translate(-50%, -100%)' },
    enter: { opacity: 1, transform: 'translate(-50%, -50%)' },
    leave: { opacity: 0, transform: 'translate(-50%, -100%)' },
  });

  return transitions.map(
    ({ item, key, props: style }) =>
      item && (
        <Container
          dark={dark ? 'true' : undefined}
          data-testid={dataTestId}
          expanded={expanded ? 'true' : undefined}
          key={key}
          open={open}
          style={style}
        >
          <>
            <Section key={key} style={props} zebraStripe={zebraStripe}>
              <Centered>
                {title ? (
                  <>
                    <Subtitle>
                      <TextStyle warning>{title}</TextStyle>
                    </Subtitle>
                    <PaddedContent pushTop="md">
                      <MaxHeight height={450}>
                        <TextStyle fontSize="sm">{props.children || message}</TextStyle>
                      </MaxHeight>
                    </PaddedContent>
                  </>
                ) : (
                  <MaxHeight height={250}>
                    <TextStyle fontSize="sm">{props.children || message}</TextStyle>
                  </MaxHeight>
                )}
              </Centered>
            </Section>
            <Centered>
              <Button onClick={props.onDismiss}>Dismiss</Button>
            </Centered>
          </>
        </Container>
      )
  );
}
