import styled, { css } from 'styled-components';

const Inline = styled.div<{ large?: boolean; small?: boolean }>`
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;

  ${({ large }) =>
    large &&
    css`
      width: 40%;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 20%;
    `};
`;

Inline.displayName = 'Inline';

export default Inline;
