import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import ConditionalRender from '../ConditionalRender';

import Icon from './Icon';

const Container = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.grayBlue};
  cursor: pointer;
  font-size: ${rem(12)};
  font-weight: 700;
  display: flex;
  padding: ${({ theme }) => rem(theme.spacing.sm)};
  text-transform: none;

  &:hover {
    color: ${({ theme }) => theme.colors.darkSlateGray};
    path {
      fill: ${({ theme }) => theme.colors.darkSlateGray};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.lightGray};
      path {
        fill: ${({ theme }) => theme.colors.lightGray};
      }
      &:hover {
        color: ${({ theme }) => theme.colors.lightGray};
        cursor: not-allowed;
        path {
          fill: ${({ theme }) => theme.colors.lightGray};
        }
      }
    `}
`;

export default function Option({ children, disabled = false, icon, ...rest }) {
  return (
    <Container disabled={disabled} {...rest}>
      <Icon name={icon} />
      <ConditionalRender condition={Boolean(children)}>
        {' '}
        <span>{children}</span>
      </ConditionalRender>
    </Container>
  );
}
