import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as BellIcon } from './bell.svg';
import { ReactComponent as ErrorIcon } from './error.svg';
import { ReactComponent as SuccessIcon } from './success.svg';
import { ReactComponent as WarningIcon } from './warning.svg';

const Icon = ({ className, name, small = false }) => {
  switch (name) {
    case 'bell':
      return <BellIcon className={className} data-testid="error-icon" small={small.toString()} />;
    case 'error':
      return <ErrorIcon className={className} data-testid="error-icon" small={small.toString()} />;
    case 'success':
      return <SuccessIcon className={className} data-testid="success-icon" small={small.toString()} />;
    case 'warning':
      return <WarningIcon className={className} data-testid="warning-icon" small={small.toString()} />;
    default:
      return null;
  }
};

export default styled(Icon)`
  height: ${rem(24)};
  position: relative;
  width: ${rem(24)};

  ${({ small }) =>
    small &&
    css`
      height: ${rem(15)};
      width: ${rem(15)};
    `};
`;
