import styled from 'styled-components';
import Select from 'react-select';

const Dropdown = styled(Select)`
  width: 300px;
`;

Dropdown.displayName = 'Dropdown';

export default Dropdown;
