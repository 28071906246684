import React, { PureComponent } from 'react';

export const Context = React.createContext({ openMenuId: null, setOpenMenuId: null });

export default class Provider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openMenuId: null,
      setOpenMenuId: (menuId) => {
        this.setState({ openMenuId: menuId });
      },
    };
  }

  setOpenMenuId = (menuId) => {
    this.setState({ openMenuId: menuId });
  };

  render() {
    const { children } = this.props;
    const { openMenuId, setOpenMenuId } = this.state;
    return <Context.Provider value={{ openMenuId, setOpenMenuId }}>{children}</Context.Provider>;
  }
}
