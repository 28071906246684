import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
`;

export default Container;
