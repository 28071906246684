import { rgba, rem } from 'polished';
import styled, { css } from 'styled-components';

const Menu = styled.div.attrs({ role: 'menu' })`
  background-color: ${({ theme }) => theme.colors.ghostWhite};
  border: ${({ theme }) => theme.colors.glitter} solid ${rem(1)};
  border-radius: ${rem(6)};
  box-shadow: ${({ theme }) => rgba(theme.colors.jet, 0.08)} 0 ${rem(4)} ${rem(8)};
  float: left;
  right: ${rem(0)};
  position: absolute;
  top: ${rem(24 + 4)};
  transition: opacity 150ms ${({ theme }) => theme.timing.easeOutCirc},
    transform 150ms ${({ theme }) => theme.timing.easeOutBack}, visibility 0s linear 0s;
  width: ${({ collapse = false }) => (collapse ? 'auto' : rem(190))};
  z-index: 999;

  ${({ direction }) =>
    direction === 'right' &&
    css`
      left: ${rem(24 + 4)};
      top: ${rem(24 + 4)};
    `};

  ${({ direction }) =>
    direction === 'up' &&
    css`
      bottom: ${rem(30)};
      top: auto;
    `};

  &[aria-hidden='true'] {
    opacity: 0;
    transform: ${({ direction }) => `translate3d(0, ${rem(direction === 'bottom' ? 8 : -8)}, 0);`};
    transition: opacity 150ms ${({ theme }) => theme.timing.easeOutCirc},
      transform 150ms ${({ theme }) => theme.timing.easeOutBack}, visibility 0s linear 150ms;
    visibility: hidden;
  }
`;

Menu.displayName = 'Menu';

export default Menu;
