import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Header = styled.th<{ textAlign?: string }>`
  color: ${({ theme }) => theme.colors.independence};
  font-size: ${rem(12)};
  font-weight: 500;
  padding-bottom: ${({ theme }) => rem(theme.spacing.sm)};
  text-align: ${({ textAlign = 'left' }) => textAlign};
  text-transform: uppercase;
  word-wrap: break-word;

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};
`;

export default Header;
