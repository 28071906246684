import { rem } from 'polished';
import styled from 'styled-components';

const Label = styled.label`
  color: #989898;
  font-size: ${rem(14)};
  margin-left: ${rem(8)};
  margin-right: ${rem(8)};
  white-space: nowrap;
`;

export default Label;
