import React from 'react';

import ExternalLink from './ExternalLink';
import InternalLink from './InternalLink';

const Link = ({ children, dataTestId = 'atom-link', external = false, target, to, ...rest }) =>
  external ? (
    <ExternalLink data-testid={dataTestId} href={to} target={target} {...rest}>
      {children}
    </ExternalLink>
  ) : (
    <InternalLink data-testid={dataTestId} to={to} {...rest}>
      {children}
    </InternalLink>
  );

export default Link;
