import React from 'react';
import styled from 'styled-components';

import ConditionalRender from '../ui/ConditionalRender';
import PopupMenu, { Group, Option } from '../ui/PopupMenu';

import Info from '../ui/molecules/Info';

import { usePermissions } from '../hooks/usePermissions';

const ReleaseMenuContainer = styled.div`
  align-items: center;
  display: flex;
`;

export default function RowMenu({
  handlePreviews,
  handleRedressActions,
  handleRetract,
  hasRedressActions,
  logsExpanded,
  optionDisabled,
  showDetails,
}) {
  const {
    permissions: { retractionPermitted },
  } = usePermissions();

  return (
    <>
      <PopupMenu dataTestId="trace-menu">
        <Group>
          <Option icon="viewDetails" onClick={showDetails}>
            {logsExpanded ? 'Close Logs' : 'Logs'}
          </Option>
        </Group>

        <ConditionalRender condition={hasRedressActions}>
          <Group>
            <Option icon="search" onClick={handleRedressActions}>
              Redress Actions
            </Option>
          </Group>
        </ConditionalRender>

        <ConditionalRender condition={retractionPermitted}>
          <>
            <Group>
              <ReleaseMenuContainer>
                <Option disabled={optionDisabled} icon="preview" onClick={handlePreviews}>
                  Preview
                </Option>
                <ConditionalRender condition={optionDisabled}>
                  <Info tooltipPosition="left">No recipient domains are configured.</Info>
                </ConditionalRender>
              </ReleaseMenuContainer>
            </Group>

            <Group>
              <ReleaseMenuContainer>
                <Option disabled={optionDisabled} icon="retract" onClick={handleRetract}>
                  Retract
                </Option>
                <ConditionalRender condition={optionDisabled}>
                  <Info tooltipPosition="left">No recipient domains are configured.</Info>
                </ConditionalRender>
              </ReleaseMenuContainer>
            </Group>
          </>
        </ConditionalRender>
      </PopupMenu>
    </>
  );
}
