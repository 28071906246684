import axios from 'axios';
import Cookies from 'js-cookie';

import { SHERLOCK_PERMISSIONS_COOKIE } from './hooks/usePermissions';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      Cookies.remove(SHERLOCK_PERMISSIONS_COOKIE);
      window.location.assign(`/permit?RelayState=${window.location.origin}`);
    }

    return Promise.reject(error);
  }
);
