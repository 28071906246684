import axios from 'axios';
import { get } from 'lodash';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useCustomers } from '../hooks/useCustomers';

import Loading from '../ui/Loading';
import NoData from '../ui/NoData';
import { Body, Cell, Row, Container as Table } from '../ui/Table';
import TableHeader from '../ui/TableHeader';

import Timestamp from '../ui/molecules/Timestamp';

import { formatUTC } from '../utils';

const LoadingCell = styled(Cell)`
  height: ${rem(100)};
`;

/*
  ------------------   DetailTable Component   ---------------------------------------------------
*/

export default function DetailTable({ clientName, fetchedLogs, id, logs, setFetchedLogs }) {
  const { customers, error: customersError, loading: customersLoading } = useCustomers();

  const [logsLoading, setLogsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const handleFetchLogs = async () => {
    setLogsLoading(true);
    setServerError('');

    if (customersError || customersLoading) {
      setServerError('Error loading customers');
      return;
    }

    const customerId = customers.find((customer) => customer.value.clientName === clientName)?.value?.clientId;

    try {
      const { data: postfixLogs } = await axios.get(`/search?id=${id}&client_id=${customerId}`);
      const pendingUpdate = get(postfixLogs, 'responseExplained', '') === 'Pending database update.';

      const inbound = get(postfixLogs, 'inbound.lines', []) || [];
      // sometimes a line does not have a postfixId
      inbound.forEach((line) => {
        line.postfixId = postfixLogs.inbound.postfixId;
      });

      const outbound = get(postfixLogs, 'outbound.lines', []) || [];
      outbound.forEach((line) => {
        line.postfixId = postfixLogs.outbound.postfixId;
      });

      const forward = get(postfixLogs, 'forward') ? get(postfixLogs, 'forward') : [];

      /*
       The data structure of forward lines is different than inbound and outbound
            {
              forward: [
                {
                  lines: [{lineno: 718151, message: "client=ip-172-25-9-183.us-west-2.compute.internal[172.25.9.183]", ts: "2021-01-27T04:26:01"}],
                  pendingDatabase: false,
                  postfixId: "4DQVsP6F4VzNkKh"
                }
              ]
            }
        */

      const forwardLines = forward.reduce(
        (allLines, { lines, postfixId }) => [
          ...allLines,
          ...lines.reduce((moreLines, line) => [...moreLines, { ...line, postfixId }], []),
        ],
        []
      );

      setFetchedLogs({
        ...fetchedLogs,
        [id]: {
          lines: [...inbound, ...outbound, ...forwardLines],
          pendingUpdate,
        },
      });
    } catch (error) {
      setServerError(get(error, 'message', 'Error Fetching Logs'));
    }

    setLogsLoading(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!logs) {
      handleFetchLogs();
    }
    // just run the effect once. Exhaustive deps wants handleFetchLogs, logs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNoData = () => {
    if (!logs?.pendingUpdate && !logs?.lines.length) {
      return <NoData message="No Data" />;
    }
    if (logs?.pendingUpdate) {
      return <NoData message="Details are pending an update to the database. Try again in a few minutes." />;
    }
    return <NoData message="Error loading logs." />;
  };

  const renderNotice = () => {
    if (logsLoading)
      return (
        <LoadingCell colSpan="3">
          <Loading />
        </LoadingCell>
      );

    return <Cell colSpan="3">{renderNoData()}</Cell>;
  };

  return (
    <Table data-testid="trace-table" dimmed fixed spaced zebraStripes>
      <TableHeader
        colWidths={['20%', '20%', '60%']}
        headings={['Date (UTC)', 'Postfix ID', 'Message']}
        textAlign="left"
      />

      {(logsLoading || logs?.pendingUpdate || !logs?.lines.length || serverError) && (
        <Body>
          <Row />
          <Row>{renderNotice()}</Row>
        </Body>
      )}

      {!logsLoading && !serverError && !logs?.pendingUpdate && logs?.lines.length > 0 && (
        <Body>
          {logs?.lines.map(({ message, postfixId, ts }, i) => (
            <Row key={message + i}>
              <Cell>
                <Timestamp date={formatUTC(ts)} />
              </Cell>
              <Cell>{postfixId}</Cell>
              <Cell>{message}</Cell>
            </Row>
          ))}
        </Body>
      )}
    </Table>
  );
}
