import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

const Content = styled.article<{ centered?: boolean; small?: boolean }>`
  font-size: ${rem(14)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: 400;
    margin: 0 0 ${({ theme }) => rem(theme.spacing.sm)} 0;
    text-transform: uppercase;
  }

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};

  ${({ small }) =>
    small &&
    css`
      font-size: ${rem(12)};

      a {
        font-size: ${rem(12)};
      }
    `};
`;

const StyledContent = styled(Content).attrs({ 'data-testid': 'molecule-no-data' })`
  padding: ${({ theme }) => rem(theme.spacing.lg)} 0;
`;

interface Props {
  children?: React.ReactChildren | React.ReactChild;
  message?: React.ReactChild;
}

const NoData: React.FC<Props> = ({ children, message = 'copy' }): React.ReactElement => {
  return <StyledContent centered>{`- ${children || message} -`}</StyledContent>;
};

export default NoData;
