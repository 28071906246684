import styled, { css } from 'styled-components';

const Center = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 25px 0px;

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      margin: 2px 5px;
    `};

  ${({ lessMargin }) =>
    lessMargin &&
    css`
      margin: 2px 0px;
    `};
`;

Center.displayName = 'Center';

export default Center;
