import { hash } from '@a1s/lib';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { DEFAULT_WAIT_SECS, formatUTC } from '../utils';

import Badge from '../ui/Badge';
import ConditionalRender from '../ui/ConditionalRender';
import MaxHeight from '../ui/MaxHeight';
import NoData from '../ui/NoData';
import { Body, Cell, Row, Container as Table } from '../ui/Table';
import TableHeader from '../ui/TableHeader';

import Timestamp from '../ui/molecules/Timestamp';

import { Disposition, MissedTranslations, PrioritizedDetection, SearchResult, Status } from '../types';

import Closer from './Closer';
import ContentViewer from './ContentViewer';
import Form from './Form';
import RowMenu from './RowMenu';

import type { QueryValues } from './Form';

import 'react-tabs/style/react-tabs.css';
import './SearchApp.css';

// see API docs: http://confluence.area1security.com:8090/display/DOC/MailSearch+REST+API

const HR = styled.hr`
  margin-bottom: ${({ theme }) => rem(theme.spacing.md)};
`;

const MissedDetection = styled.p`
  font-weight: 600;
  margin-left: ${({ theme }) => rem(theme.spacing.xs)};
  margin-top: ${({ theme }) => rem(theme.spacing.xs)};
`;

const StyledBadge = styled(Badge)`
  margin-top: ${({ theme }) => rem(theme.spacing.xs)};
`;

const StyledCell = styled(Cell)<{ centered?: 'middle' | 'top' | boolean }>`
  font-size: ${rem(11)};
  height: 100%;
  vertical-align: ${({ centered }) => (centered ? 'middle' : 'top')};
  word-wrap: break-word !important;

  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
`;

const UL = styled.ul`
  margin: ${rem(0)};
  padding: 0 0 0 ${rem(20)};
`;

/*
    SearchApp Component -------------------------------------------------------------------------------------
*/

export type emailViewHandler = (args: { row: SearchResult; renderImages: boolean; index: number }) => void;

export default function SearchApp(): React.ReactElement {
  const [index, setIndex] = useState(-1);
  const [renderImages, setRenderImages] = useState(true);
  const [rows, setRows] = useState<SearchResult[]>([]);
  const [selectedRow, setSelectedRow] = useState<SearchResult | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showPreviewTab, setShowPreviewTab] = useState(true);
  const [status, setStatus] = useState<Status>(null);
  const [formValues, setFormValues] = useState<QueryValues>({});
  const [waitSeconds, setWaitSeconds] = useState(DEFAULT_WAIT_SECS);

  useEffect(() => {
    window.addEventListener('keyup', handleCloseModal);
    return () => window.removeEventListener('keyup', handleCloseModal);
  });

  const handleCloseModal = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Escape' && showModal) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  });

  const handleKeyUp = (e: KeyboardEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.key === 'Escape' && showModal) {
      setShowModal(false);
    }

    // allow user to auto-preview rows in the results table with up/down arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      if (e.key === 'ArrowUp' && !!rows[index - 1]) {
        setIndex(index - 1);
        setSelectedRow(rows[index - 1]);
        setShowModal(true);
      }

      if (e.key === 'ArrowDown' && !!rows[index + 1]) {
        setIndex(index + 1);
        setSelectedRow(rows[index + 1]);
        setShowModal(true);
      }
    }
  };

  const setSearchResult = (rows: SearchResult[], status: Status) => {
    setRows(rows);
    setStatus(status);
  };

  const handleEmailView: emailViewHandler = ({ row, renderImages, index }) => {
    if (!renderImages) {
      setShowPreviewTab(false);
    } else {
      setShowPreviewTab(true);
    }
    setIndex(index);
    setSelectedRow(row);
    setRenderImages(renderImages);
    setShowModal(true);
  };

  return (
    <>
      <Form
        onValuesChanged={(values: QueryValues) => setFormValues(values)}
        setSearchResult={setSearchResult}
        setWaitSeconds={setWaitSeconds}
      />

      <ConditionalRender condition={rows.length > 0}>
        <HR />
        <Table data-testid="search-table" dimmed fixed spaced verticalAlign="top" zebraStripes>
          <TableHeader
            colWidths={['8%', '7%', '12%', '18%', '24%', '31%', 65]}
            headings={['Date(UTC)/ Disposition', 'Customer', 'From', 'Recipient(s)', 'Subject', 'Reasons', '']}
          />

          <ConditionalRender
            condition={rows.length > 0}
            fallback={
              <Body>
                <Row />
                <Row>
                  <Cell colSpan={7}>
                    <NoData message="No Data" />
                  </Cell>
                </Row>
              </Body>
            }
          >
            <Body>
              {rows &&
                rows.map((row, i) => {
                  const {
                    bccRecipient,
                    cc,
                    clientName,
                    detectionReasons,
                    envelopeTo,
                    finalDisposition = '',
                    from,
                    prioritizedDetection = null,
                    subject,
                    to,
                    ts,
                    vendorMisses,
                  } = row;

                  const recipients = getUniqueRecipientsAsString(to, envelopeTo, cc, bccRecipient);

                  return (
                    <Row key={hash(row)} selected={i === index}>
                      <StyledCell>
                        {renderTimeAndDisposition(
                          formatUTC(ts as string),
                          finalDisposition as Disposition,
                          prioritizedDetection,
                          vendorMisses
                        )}
                      </StyledCell>
                      <StyledCell>{clientName}</StyledCell>
                      <StyledCell>
                        <MaxHeight height={150}>{from}</MaxHeight>
                      </StyledCell>
                      <StyledCell>
                        <MaxHeight height={150}>{recipients}</MaxHeight>
                      </StyledCell>
                      <StyledCell>{subject}</StyledCell>
                      <StyledCell>
                        <MaxHeight height={150}>
                          <UL>
                            {detectionReasons.map((reason) => (
                              <li dangerouslySetInnerHTML={{ __html: reason }} key={reason} />
                            ))}
                          </UL>
                        </MaxHeight>
                      </StyledCell>
                      <StyledCell centered>
                        <RowMenu handleEmailView={handleEmailView} index={i} row={row} />
                      </StyledCell>
                    </Row>
                  );
                })}
            </Body>
          </ConditionalRender>
        </Table>
      </ConditionalRender>

      <ConditionalRender condition={status !== null && rows.length > 0}>{displayStatusText(status)}</ConditionalRender>

      <Modal isOpen={showModal}>
        <Closer onClose={() => setShowModal(false)}></Closer>
        <ContentViewer
          formValues={formValues}
          renderImages={renderImages}
          row={selectedRow}
          showPreviewTab={showPreviewTab}
          waitSeconds={waitSeconds}
        />
      </Modal>
    </>
  );
}

/*
  Private functions
*/

const renderTimeAndDisposition = (
  time: string,
  disposition: Disposition,
  prioritizedDetection: null | PrioritizedDetection | undefined,
  vendorMisses: PrioritizedDetection[]
): React.ReactElement => (
  <div>
    <Timestamp date={time} />
    <StyledBadge disposition={disposition}>{disposition}</StyledBadge>
    <ConditionalRender
      condition={vendorMisses?.length > 0}
      fallback={<MissedDetection>{MissedTranslations[prioritizedDetection as PrioritizedDetection]}</MissedDetection>}
    >
      <>
        {vendorMisses.map((miss, i) => (
          <MissedDetection key={i}>{MissedTranslations[miss as PrioritizedDetection]}</MissedDetection>
        ))}
      </>
    </ConditionalRender>
  </div>
);

function displayStatusText(status: Status): React.ReactElement {
  const { statusCode, text } = status || {};

  let responseStatus = 'unknown';
  if (!!statusCode && statusCode > 300) {
    responseStatus = 'error';
  } else if (!!statusCode && statusCode >= 200 && statusCode <= 300) {
    responseStatus = 'ok';
  }

  const renderStatus = () => {
    switch (responseStatus) {
      case 'ok':
        return <p style={{ color: 'green' }}>{text}</p>;
      case 'error':
        return <p style={{ color: 'red' }}>{text}</p>;
      case 'unknown':
        return <p style={{ color: 'blue' }}>{text}</p>;
      default:
        return <p style={{ color: 'black' }}>{text}</p>;
    }
  };

  return (
    <div>
      <p>Status: </p>
      {renderStatus()}
    </div>
  );
}

const getUniqueRecipientsAsString = (...recipientsArray: Array<string | string[] | null>) => {
  const recipientSet = new Set<string>();
  recipientsArray.forEach((recipients: string | string[] | null) => {
    if (Array.isArray(recipients)) {
      recipients.forEach((recipient: string) => {
        if (recipient) recipientSet.add(recipient);
      });
    }
    if (recipients && typeof recipients === 'string') {
      recipientSet.add(recipients);
    }
  });

  return [...recipientSet].join(', ');
};
