import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { endOfDay, endOfMonth, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns';
import { defer } from 'lodash';
import { rem, rgba } from 'polished';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  align-items: center;
  display: flex;
  line-height: 32px;
  margin: 0 ${rem(8)};
`;

const Picker = styled(DateTimeRangePicker)`
  flex-grow: 20;
  width: auto;

  .react-datetimerange-picker__wrapper {
    border: 0;
  }

  .react-datetimerange-picker__inputGroup {
    &:first-of-type {
      margin-right: ${rem(8)};
    }

    &:last-of-type {
      margin-left: ${rem(8)};
    }
  }

  .react-datetimerange-picker__wrapper,
  .react-datetimerange-picker__inputGroup__leadingZero,
  button,
  input,
  select {
    color: '#6699cc';
    font-family: sans-serif;
    font-size: ${rem(15)};
  }

  input,
  select {
    background-color: inherit !important;
  }

  select {
    appearance: none;
  }

  .react-calendar {
    background-color: '#f8f8ff';
    border: '#e6e8fa' solid ${rem(1)};
    border-radius: 0 ${rem(6)} ${rem(6)} 0;
    box-shadow: '#343434' 0 ${rem(4)} ${rem(8)};
    position: relative;

    z-index: 1;
  }

  .react-calendar__navigation__arrow:enabled:hover,
  .react-calendar__navigation__label:enabled:hover {
    background-color: '#dbe9f4';
    color: '#778899';
  }

  .react-calendar__month-view__weekdays,
  abbr {
    font-family: sans-serif;
    font-size: ${rem(15)};
    text-decoration: none;
  }

  .react-calendar__tile--hasActive {
    background: '#00bfff';
    color: '#ffffff';
  }

  .react-calendar__tile--rangeStart {
    border-bottom-left-radius: ${rem(2)};
    border-top-left-radius: ${rem(2)};
  }

  .react-calendar__tile--rangeEnd {
    border-bottom-right-radius: ${rem(2)};
    border-top-right-radius: ${rem(2)};
  }

  .react-calendar__tile--active {
    color: '#ffffff';
  }
`;

const Shortcuts = styled.div`
  background-color: white;
  border: '#e6e8fa' solid ${rem(1)};
  border-radius: ${rem(6)} 0 0 ${rem(6)};
  box-shadow: ${rgba('#343434', 0.08)} 0 ${rem(4)} ${rem(8)};
  display: none;
  padding: ${rem(16)} ${rem(8)};
  position: fixed;
  width: ${rem(95)};
  z-index: 10;

  ${(p) =>
    p.isOpen &&
    css`
      display: block;
    `};
`;

const Shortcut = styled.button`
  background-color: white;
  color: '#2f4f4f';
  cursor: pointer;
  display: block;
  font-family: sans-serif;
  font-size: ${rem(15)};
  margin-bottom: ${rem(16)};
  border: 0;
  padding: 0;

  &:hover {
    color: '#778899';
  }
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function DateRangePicker({ onChange, value, ...props }) {
  const calRef = useRef(null);
  const shortcutsRef = useRef(null);
  const [isOpen, setIsOpen] = useState();

  function handleCalendarClose() {
    setIsOpen(false);
  }

  function handleCalendarOpen() {
    setIsOpen(true);
    layout(calRef.current, shortcutsRef.current);
  }

  function handleMouseDown(getRange) {
    return (event) => {
      event.stopPropagation();
      onChange(getRange.call());
    };
  }

  function handleMouseUp() {
    defer(() => layout(calRef.current, shortcutsRef.current));
  }

  return (
    <Container onMouseUp={handleMouseUp}>
      <Shortcuts isOpen={isOpen} ref={shortcutsRef}>
        <Shortcut onMouseDown={handleMouseDown(today)}>Today</Shortcut>
        <Shortcut onMouseDown={handleMouseDown(yesterday)}>Yesterday</Shortcut>
        <Shortcut onMouseDown={handleMouseDown(last7Days)}>Last 7 Days</Shortcut>
        <Shortcut onMouseDown={handleMouseDown(last30Days)}>Last 30 Days</Shortcut>
        <Shortcut onMouseDown={handleMouseDown(last90Days)}>Last 90 Days</Shortcut>
        <Shortcut onMouseDown={handleMouseDown(thisMonth)}>This Month</Shortcut>
        <Shortcut onMouseDown={handleMouseDown(lastMonth)}>Last Month</Shortcut>
      </Shortcuts>

      <Picker
        disableClock
        calendarIcon={null}
        clearIcon={null}
        onChange={onChange}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        ref={calRef}
        value={value}
        {...props}
      />
    </Container>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function last7Days() {
  const start = subDays(new Date(), 7);
  const end = new Date();
  return [startOfDay(start), endOfDay(end)];
}

function last30Days() {
  const start = subDays(new Date(), 30);
  const end = new Date();
  return [startOfDay(start), endOfDay(end)];
}

function last90Days() {
  const start = subDays(new Date(), 90);
  const end = new Date();
  return [startOfDay(start), endOfDay(end)];
}

function lastMonth() {
  const date = subMonths(new Date(), 1);
  return [startOfMonth(date), endOfMonth(date)];
}

function layout(calEl, shortcutsEl) {
  if (calEl && calEl.wrapper && shortcutsEl) {
    const [element] = calEl.wrapper.getElementsByClassName('react-calendar');
    if (element) {
      const elementRect = element.getBoundingClientRect();

      /* eslint-disable no-param-reassign */
      shortcutsEl.style.height = `${elementRect.height}px`;
      shortcutsEl.style.left = `${elementRect.left - 120}px`;
      shortcutsEl.style.top = `${elementRect.top}px`;
      /* eslint-enable no-param-reassign */
    }
  }
}

function thisMonth() {
  const date = new Date();
  return [startOfMonth(date), endOfDay(date)];
}

function today() {
  const date = new Date();
  return [startOfDay(date), endOfDay(date)];
}

function yesterday() {
  const date = subDays(new Date(), 1);
  return [startOfDay(date), endOfDay(date)];
}
