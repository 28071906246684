import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as RedressActionsIcon } from '../../PopupMenu/icons/mail-redress-actions.svg';

import { ReactComponent as InfoIcon } from './info.svg';

const Info = styled(InfoIcon).attrs({ alt: 'Information Icon' })`
  bottom: 0;
  cursor: help;
  height: ${rem(14)};
  width: ${rem(14)};

  &:hover path {
    fill: ${({ theme }) => theme.colors.black} !important;
  }
`;

const RedressActions = styled(RedressActionsIcon).attrs({ alt: 'Redress Actions Icon' })`
  bottom: 0;
  cursor: help;
  height: ${rem(14)};
  width: ${rem(14)};

  &:hover path {
    fill: ${({ theme }) => theme.colors.black} !important;
  }
`;

const Target = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const Icon = (props, ref) => {
  const { icon } = props;

  const getIcon = () => {
    switch (icon) {
      case 'mail-retract':
        return <RedressActions />;
      default:
        return <Info />;
    }
  };

  return (
    <>
      {getIcon()}
      <Target ref={ref} {...props} />
    </>
  );
};

// $FlowFixMe
export default React.forwardRef(Icon);
