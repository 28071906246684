import { rem } from 'polished';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent } from './settings.svg';

//
// Styled Components
// -------------------------------------------------------------------------------------------------

const Icon = styled(ReactComponent)`
  height: ${rem(16)};
  width: ${rem(14)};

  a:hover & path {
    fill: #6699cc;
  }
`;

const Link = styled(NavLink)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${rem(32)};
  justify-content: center;
  width: ${rem(32)};
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  height: ${rem(24)};
  justify-content: center;
  transition: background-color 150ms 'cubic-bezier(0.075, 0.82, 0.165, 1)';
  width: ${rem(24)};

  a[aria-current='page'] & {
    background-color: #f2f3f4;
  }

  a[aria-current='page'] & path {
    fill: #00bfff;
  }
`;

//
// Main Component
// -------------------------------------------------------------------------------------------------

const SettingsLink: React.FC = (): React.ReactElement => {
  return (
    <Link title="Settings" to="/settings">
      <Wrapper>
        <Icon />
      </Wrapper>
    </Link>
  );
};

export default SettingsLink;
