import hash from 'hash-sum';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled, { css } from 'styled-components';

import BlockHeader from '../ui/BlockHeader';
import ConditionalRender from '../ui/ConditionalRender';
import Rule from '../ui/Rule';
import { Body, Cell, Row, Container as Table } from '../ui/Table';
import TableHeader from '../ui/TableHeader';

import PaddedContent from '../ui/atoms/PaddedContent';
import StatusIcon from '../ui/atoms/StatusIcon';
import TooltippedStatusIcon from '../ui/molecules/TooltippedStatusIcon';

import Content from '../ui/molecules/Content';
import Timestamp from '../ui/molecules/Timestamp';

import { formatUTC } from '../utils';

const StyledCell = styled(Cell)`
  vertical-align: top;
  word-wrap: break-word !important;
  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};
`;

const StyledTabs = styled(Tabs)`
  max-width: 97%;

  li {
    border: none;
  }
`;

/*
  ------------------   RedressActionDetail Component   ---------------------------------------------------
*/

export default function RedressActionDetail({ redressActions }) {
  return (
    <StyledTabs>
      <TabList>
        <Tab />
      </TabList>
      <TabPanel>
        <RedressActions redressActions={redressActions} />
      </TabPanel>
    </StyledTabs>
  );
}

/*
  -------------  Private Component  ------------------
*/

const redressPageDescription =
  "A Redress Action is any action taken on an email after it transits Area 1's processing. Actions include PREVIEW, QUARANTINE RELEASE, and RETRACTION.";

function RedressActions({ redressActions }) {
  return (
    <>
      <BlockHeader title="Redress Actions" />
      <Content>{redressPageDescription}</Content>

      <PaddedContent pullBottom="lg" pullTop="lg">
        <Rule />
      </PaddedContent>

      <ConditionalRender condition={!!redressActions.length}>
        <Table data-testid="redress-actions-successful-table" fixed spaced zebraStripes>
          <TableHeader
            colWidths={['15%', '25%', '10%', '30%', '10%', '5%', '5%']}
            headings={[
              'Action Type',
              'Recipient',
              'Destination',
              'Message Id',
              'Start/End Times (UTC)',
              'Count',
              'Status',
            ]}
            textAlign={(heading) => (heading === 'Count' || heading === 'Status' ? 'center' : 'left')}
          />

          <Body>
            {redressActions.map((action) => {
              const {
                completedTimestamp,
                destination,
                itemCount,
                operation,
                messageId,
                recipient,
                startedTimestamp,
                status,
              } = action;

              return (
                <Row key={hash(action)}>
                  <StyledCell>{actionTranslation[operation] || operation}</StyledCell>
                  <StyledCell>{recipient}</StyledCell>
                  <StyledCell>{destination ? destination : 'na'}</StyledCell>
                  <StyledCell>{messageId}</StyledCell>
                  <StyledCell>{renderStartEnd(startedTimestamp, completedTimestamp)}</StyledCell>
                  <StyledCell centered>{itemCount}</StyledCell>
                  <StyledCell centered>{getStatusIcon(status)}</StyledCell>
                </Row>
              );
            })}
          </Body>
        </Table>
      </ConditionalRender>
    </>
  );
}

/*
    Private Functions
*/

const getStatusIcon = (status) => {
  if (status === 'OK' || status === 'SUCCESS') {
    return <StatusIcon name={'success'} noMargin small />;
  } else
    return (
      <TooltippedStatusIcon name={'error'} noMargin small tooltipContents={<p>{status}</p>} tooltipPosition="left" />
    );
};

const renderStartEnd = (startedTimestamp, completedTimestamp) => (
  <>
    <div>
      <Timestamp date={formatUTC(startedTimestamp)} />
    </div>
    <div>
      <Timestamp date={formatUTC(completedTimestamp)} />
    </div>
  </>
);

const actionTranslation = {
  QUARANTINE_RELEASE: 'QUARANTINE RELEASE',
};
