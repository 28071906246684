import React from 'react';

interface Props {
  children: React.ReactElement | React.ReactElement[];
  condition: boolean | undefined | string;
  fallback?: React.ReactElement;
}

const ConditionalRender: React.FC<Props> = ({ children, condition, fallback }): React.ReactElement | null => {
  if (condition) return <>{children}</>;
  return fallback || null;
};

export default ConditionalRender;
