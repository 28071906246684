import styled from 'styled-components';

const Backdrop = styled.div`
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

export default Backdrop;
