import React from 'react';

import Center from '../ui/Center';
import ConditionalRender from '../ui/ConditionalRender';
import PopupMenu, { Group, Option } from '../ui/PopupMenu';

import { usePermissions } from '../hooks/usePermissions';

const PREVIEW_LIMIT = 10;

export default function MultiSelectActionsMenu({
  changePreviewsModalState,
  messages,
  messagesNoRecipients,
  selectedRows,
  setAlertOpen,
  setSelectedRows,
}) {
  const {
    permissions: { retractionPermitted },
  } = usePermissions();

  const handleSelectAll = () => {
    const selectedSet = new Set();
    messages.forEach(({ clientRecipients, key }) => {
      if (clientRecipients.length) setSelectedRows(selectedSet.add(key));
    });
  };

  const handleSelectNone = () => {
    setSelectedRows(new Set());
  };

  const allSelected = selectedRows.size === messages.length - messagesNoRecipients.length;

  return (
    <>
      <PopupMenu dataTestId="trace-menu" direction="right">
        <Group>
          <Center lessMargin>Retract Options</Center>
          <Option icon={allSelected ? 'uncheck' : 'check'} onClick={allSelected ? handleSelectNone : handleSelectAll}>
            {allSelected ? 'Clear Selections' : 'Select All'}
          </Option>
          <ConditionalRender condition={selectedRows.size > 0 && retractionPermitted}>
            <Option icon="reload" onClick={() => setAlertOpen(true)}>
              {`Retract ${selectedRows.size}`}
            </Option>
          </ConditionalRender>
        </Group>

        <ConditionalRender condition={retractionPermitted}>
          <Group>
            <Center lessMargin>Preview Options</Center>
            <ConditionalRender condition={selectedRows.size > 0 && selectedRows.size <= PREVIEW_LIMIT}>
              <Option
                icon="search"
                onClick={() => changePreviewsModalState({ messageSet: selectedRows, showModal: true })}
              >
                {`Preview ${selectedRows.size}`}
              </Option>
            </ConditionalRender>
            <PreviewOptions changePreviewsModalState={changePreviewsModalState} messages={messages} />
          </Group>
        </ConditionalRender>
      </PopupMenu>
    </>
  );
}

/*
  ------------------  Private functions -------------------------------------------
*/

function PreviewOptions({ changePreviewsModalState, messages }) {
  const numOptionsArr = [...Array(Math.ceil(messages.length / PREVIEW_LIMIT)).keys()];
  const options = numOptionsArr.map(
    (option) =>
      // renders a series of View Rows 1-10, View Rows 11-20, ... View Rows 91-100
      `View rows ${option * PREVIEW_LIMIT + 1}-${
        (option + 1) * PREVIEW_LIMIT > messages.length ? messages.length : (option + 1) * PREVIEW_LIMIT
      }`
  );

  const handlePreviewSelection = (index) => {
    let messageSet = new Set();

    for (let i = index * PREVIEW_LIMIT; i < index * PREVIEW_LIMIT + PREVIEW_LIMIT; i++) {
      if (messages[i] && messages[i].clientRecipients.length) messageSet.add(messages[i].key);
    }

    changePreviewsModalState({ messageSet, showModal: true });
  };

  return (
    <>
      {options.map((option, i) => (
        <Option icon="search" key={option} onClick={() => handlePreviewSelection(i)}>
          {option}
        </Option>
      ))}
    </>
  );
}
