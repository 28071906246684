import Cookies from 'js-cookie';
import hash from 'hash-sum';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const DEFAULT_PERMISSIONS: PermissionsType = {
  /**
   * allows access to findings configuration
   */
  configPermitted: false,
  /**
   * allows access to the app
   */
  detectionViewPermitted: false,
  /**
   * enables the view of the Raw tab
   */
  downloadPermitted: false,
  /**
   * enables view of release button/column
   */
  quarantineReleasePermitted: false,
  /**
   * allows an email to be previewed/retracted
   */
  retractionPermitted: false,
  /**
   * not exposed in the ui
   */
  reviewPermitted: false,
  /**
   * enables a search of All Customers
   */
  searchAllPermitted: false,
  /**
   * enables the view of the entire Trace page
   */
  traceViewPermitted: false,
};

export const SHERLOCK_PERMISSIONS_COOKIE = 'sherlock-permissions';

interface PermissionContext {
  permissions: PermissionsType;
  setPermissions: React.Dispatch<PermissionsType>;
}

export type PermissionsType = {
  configPermitted: boolean;
  detectionViewPermitted: boolean;
  downloadPermitted: boolean;
  quarantineReleasePermitted: boolean;
  retractionPermitted: boolean;
  reviewPermitted: boolean;
  searchAllPermitted: boolean;
  traceViewPermitted: boolean;
};

const Context = createContext<PermissionContext>({
  permissions: DEFAULT_PERMISSIONS,
  setPermissions: () => ({}),
});

export function usePermissions(): PermissionContext {
  return useContext(Context);
}

export const PermissionsProvider: React.FC = ({ children }): React.ReactElement => {
  const authFromCookie = Cookies.get(SHERLOCK_PERMISSIONS_COOKIE);
  const [permissions, setPermissions] = useState(DEFAULT_PERMISSIONS);

  const hashedAuth = hash(authFromCookie);
  const hashedPermissions = hash(permissions);

  useEffect(() => {
    const parsedAuth: PermissionsType = JSON.parse(authFromCookie || '{}');

    if (authFromCookie && hash(parsedAuth) !== hashedPermissions) {
      setPermissions(parsedAuth);
    } else if (!authFromCookie) {
      window.location.assign(`/permit?RelayState=${window.location.origin}`);
    }
    // the hashes keep the effect from an infinite loop
    // req'd are 'authFromCookie', 'hashedPermissions'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashedAuth, hashedPermissions]);

  return (
    <Context.Provider
      value={{
        permissions,
        setPermissions,
      }}
    >
      {children}
    </Context.Provider>
  );
};
