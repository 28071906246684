import { rem } from 'polished';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const InternalLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.deepSkyBlue};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(14)};
  text-decoration: none !important;

  &:active {
    color: ${({ theme }) => theme.colors.starCommandBlue};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.bleuDeFrance};
    text-decoration: underline;
  }
`;

InternalLink.displayName = 'InternalLink';

export default InternalLink;
